"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_redux_1 = require("react-redux");
const GradientButton_1 = require("../GradientButton");
const AppText_1 = require("../AppText");
const user_1 = require("../../store/selectors/user");
const subscription_1 = require("../../store/selectors/subscription");
const productStore_1 = require("../../common/productStore");
const constants_1 = require("../../common/constants");
const planDescription = {
    [constants_1.PREMIUM_MONTHLY]: 'Monthly',
    [constants_1.PREMIUM_ANNUALLY]: 'Annually',
};
function PremiumInfo(props) {
    const userId = (0, react_redux_1.useSelector)(user_1.selectfbUserId);
    const plan = (0, react_redux_1.useSelector)(subscription_1.selectActiveSubscriptionName);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { ...props, style: [styles.container, props.style], children: [(0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.headerContainer, children: [(0, jsx_runtime_1.jsxs)(AppText_1.AppText, { color: "white", fontSize: 24, fontWeight: 600, children: ["You are currently on", (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.textEmphasis, children: " Premium Plan" })] }), (0, jsx_runtime_1.jsxs)(AppText_1.AppText, { color: "#7D7D80", fontSize: 16, lineHeight: 20, children: ["Paid", ` ${planDescription[plan]}`] })] }), (0, jsx_runtime_1.jsx)(GradientButton_1.GradientButton, { text: "Manage Subscription", onPress: () => { (0, productStore_1.manageProducts)(userId); }, customStyles: { text: styles.button } })] }));
}
exports.default = PremiumInfo;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: '#242427',
        width: 552,
        maxWidth: '100%',
        gap: 24,
        alignSelf: 'center',
        padding: 20,
        borderWidth: 1,
        borderColor: '#414146',
        borderRadius: 12,
    },
    headerContainer: {
        gap: 9,
    },
    textEmphasis: {
        color: '#6558FF',
    },
    button: {
        fontWeight: '500',
    }
});
