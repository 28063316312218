"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const useAnalytics_1 = require("../../hooks/useAnalytics");
const useAppMessage_1 = require("../../hooks/useAppMessage");
const AppIcons_1 = require("../../component/AppIcons");
const AppText_1 = require("../AppText");
const colors_1 = require("../../common/colors");
const firebase_1 = require("../../auth/firebase");
/**
 *
 * @param props
 * @returns
 */
const LoginApple = ({ loginSuccessHandler, style, }) => {
    const { logEvent } = (0, useAnalytics_1.useAnalytics)();
    const { showNotification } = (0, useAppMessage_1.useAppMessage)();
    const onAppleButtonPress = (0, react_1.useCallback)(async () => {
        try {
            const { user } = await (0, firebase_1.signInPopUp)(firebase_1.appleProvider);
            if (!user) {
                return;
            }
            await logEvent("signin_google", { uid: user.uid });
            await loginSuccessHandler(user);
        }
        catch (error) {
            setTimeout(() => {
                showNotification("Sign In error", `${error}. Error while google sign in using Google`, "error");
            }, 1000);
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { style: [styles.appleButton, style], onPress: onAppleButtonPress, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.safeArea, children: [(0, jsx_runtime_1.jsx)(AppIcons_1.Apple, { height: 24, color: "#000" }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [styles.appleButtonText], children: "Continue with Apple" })] }) }) }));
};
const styles = react_native_1.StyleSheet.create({
    appleButton: {
        backgroundColor: 'white',
        borderRadius: 35,
        paddingVertical: 12,
        alignItems: 'center',
    },
    safeArea: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    appleButtonText: {
        color: colors_1.Colors.DARK_700,
        fontSize: 16,
        fontWeight: '600',
        marginLeft: '4%',
    },
});
exports.default = LoginApple;
