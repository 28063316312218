"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeeAll = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const theme_1 = require("../../helper/theme");
const colors_1 = require("../../common/colors");
const useDevice_1 = require("../../hooks/useDevice");
const AppText_1 = require("../../component/AppText");
const SeeAll = (props) => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.PRIMARY_300, fontSize: (0, theme_1.scaleFont)(16), fontWeight: 500, marginRight: isWebDevice && isMobileScreen ? 5 : undefined, ...props, children: "See All" }));
};
exports.SeeAll = SeeAll;
