"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_share_1 = require("react-share");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
const AppIcons_1 = require("../AppIcons");
const colors_1 = require("../../common/colors");
const IconContainer = ({ size, children }) => ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: [styles.iconContainer, {
            width: size,
            height: size,
        }], children: children }));
function SocialIcon({ socialMedia, sharedUrl, size, innerSize, }) {
    switch (socialMedia) {
        case "facebook":
            return ((0, jsx_runtime_1.jsx)(react_share_1.FacebookShareButton, { url: sharedUrl, children: (0, jsx_runtime_1.jsx)(IconContainer, { size: size, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.whiteBackground, children: (0, jsx_runtime_1.jsx)(AppIcons_1.FacebookLogo, { width: innerSize ?? 41.5, height: innerSize ?? 42 }) }) }) }));
        case "reddit":
            return ((0, jsx_runtime_1.jsx)(react_share_1.RedditShareButton, { url: sharedUrl, children: (0, jsx_runtime_1.jsx)(IconContainer, { size: size, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.whiteBackground, children: (0, jsx_runtime_1.jsx)(AppIcons_1.RedditLogo, { width: (innerSize ?? 42) - 0.5, height: innerSize ?? 42 }) }) }) }));
        case "X":
            return ((0, jsx_runtime_1.jsx)(react_share_1.TwitterShareButton, { url: sharedUrl, children: (0, jsx_runtime_1.jsx)(IconContainer, { size: size, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: [styles.XBackground, {
                                width: innerSize ?? 42,
                                height: innerSize ?? 42
                            }], children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faXTwitter, color: 'white', size: (innerSize ?? 42) - 10 }) }) }) }));
        case "email":
            return ((0, jsx_runtime_1.jsx)(react_share_1.EmailShareButton, { url: sharedUrl, children: (0, jsx_runtime_1.jsx)(IconContainer, { size: size, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: [styles.whiteBackground, {
                                width: innerSize ?? 42,
                                height: innerSize ?? 42
                            }], children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faEnvelope, color: colors_1.Colors.DARK_600, size: (innerSize ?? 42) - 18 }) }) }) }));
    }
    ;
}
exports.default = SocialIcon;
const styles = react_native_1.StyleSheet.create({
    iconContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    },
    whiteBackground: {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    },
    XBackground: {
        backgroundColor: '#0F1419',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    },
});
