"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const useDevice_1 = require("../../hooks/useDevice");
const AppText_1 = require("../AppText");
const colors_1 = require("../../common/colors");
const spacing_1 = require("../../common/spacing");
const borders_1 = require("../../common/borders");
function TextCard({ title, children }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.container, isMobileScreen && styles.containerMobile], children: [(0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.title, children: title }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.description, children: children })] }));
}
exports.default = TextCard;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: '#303032',
        width: 191,
        gap: spacing_1.Spacing.SPACING_MD,
        flexShrink: 0,
        padding: spacing_1.Spacing.SPACING_LG,
        borderColor: colors_1.Colors.DARK_500,
        borderWidth: 1,
        borderRadius: borders_1.Borders.RADIUS_2XL,
    },
    containerMobile: {
        width: 156,
    },
    title: {
        color: 'white',
        fontSize: 16,
        fontWeight: '600',
        lineHeight: 20,
    },
    description: {
        color: colors_1.Colors.DARK_100,
        fontSize: 12,
        lineHeight: 16,
    },
});
