"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchByValue = exports.searchByKey = exports.objectIsContainedIn = void 0;
const objectIsContainedIn = (objectA, objectB) => {
    if (objectA === undefined || objectB === undefined) {
        return false;
    }
    ;
    if (objectA instanceof Array && objectA.length !== objectB.length) {
        return false;
    }
    for (const key in objectA) {
        if (Object.prototype.hasOwnProperty.call(objectA, key)) {
            if (typeof objectA[key] === 'object') {
                const subObjectA = (objectA[key] instanceof Array ? [...objectA[key]].sort() : objectA[key]);
                const subObjectB = (objectB[key] instanceof Array ? [...objectB[key]].sort() : objectB[key]);
                if (!(0, exports.objectIsContainedIn)(subObjectA, subObjectB)) {
                    return false;
                }
                ;
            }
            else if (objectA[key] !== objectB[key]) {
                return false;
            }
            ;
        }
        ;
    }
    ;
    return true;
};
exports.objectIsContainedIn = objectIsContainedIn;
const searchByKey = (object, search) => {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            if (search === key) {
                return object[key];
            }
            const currentValue = object[key];
            if (currentValue instanceof Object) {
                const searchResult = (0, exports.searchByKey)(currentValue, search);
                if (searchResult) {
                    return searchResult;
                }
            }
        }
    }
    return null;
};
exports.searchByKey = searchByKey;
const searchByValue = (object, search) => {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const value = object[key];
            if (value instanceof Object) {
                const searchResult = (0, exports.searchByValue)(value, search);
                if (searchResult !== null) {
                    return searchResult;
                }
                ;
            }
            else {
                const searchResult = String(value).match(new RegExp(search));
                if (searchResult !== null) {
                    return searchResult;
                }
            }
        }
    }
    return null;
};
exports.searchByValue = searchByValue;
