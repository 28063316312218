"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const react_1 = require("react");
const colors_1 = require("../../common/colors");
const AppText_1 = require("../AppText");
const MembershipFeaturesList = ({ featureItems, }) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: featureItems?.map((featureItem, index) => ((0, jsx_runtime_1.jsxs)(native_base_1.HStack, { 
        // justifyContent={"center"}
        alignContent: "center", alignItems: "center", space: 5, children: [(0, jsx_runtime_1.jsx)(native_base_1.Badge //
            , { bg: colors_1.Colors.PRIMARY_100, rounded: "full", variant: "solid", alignSelf: "center", padding: 1, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheck, size: 10, color: colors_1.Colors.DARK_700 }) }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.white, fontSize: 16, lineHeight: 40, children: featureItem })] }, `feature-weekly-${index}`))) }));
exports.default = (0, react_1.memo)(MembershipFeaturesList);
