"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGptModels = exports.getUserCharacters = exports.getStats = exports.getPopularCharacters = exports.getJourneys = exports.getCollections = exports.getCollectionDetails = exports.getCollectionByTitle = exports.getDiscoverConfig = exports.getAdminCategories = void 0;
const axios_1 = require("axios");
const apis_1 = require("../common/apis");
const interceptorsAnon_1 = require("./interceptorsAnon");
const error_1 = require("../common/error");
const TUTOR_COLLECTION_ID = 2;
/**
 *
 * @param category
 * @returns
 */
const getAdminCategories = async (category) => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.categories}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getAdminCategories = getAdminCategories;
/**
 *
 * @param category
 * @returns
 */
const getUserCharacters = async (uid) => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.characters.users}?userId=${uid}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(new error_1.RequestError(error, {
                reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized
                    ? 'Session Out' : undefined
            }));
        });
    });
};
exports.getUserCharacters = getUserCharacters;
/**
 *
 * @param category
 * @returns
 */
const getStats = async (category) => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.stats}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getStats = getStats;
/**
 *
 * @param category
 * @returns
 */
const getPopularCharacters = async (category) => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.characters.popular}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getPopularCharacters = getPopularCharacters;
/**
 *
 * @param category
 * @returns
 */
const getCollections = async () => {
    return new Promise(async (resolve) => {
        // type= GROUPED or INDIVIDUAL
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.collections}?type=GROUPED`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getCollections = getCollections;
/**
 *
 * @param category
 * @returns
 */
const getJourneys = async () => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.journeys}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getJourneys = getJourneys;
/**
 *
 * @param category
 * - @Inder : Tutor tutor mode on if character is clicked.: IP
 *
 * @returns
 */
const getCollectionByTitle = async (title) => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.collectioByTitle}?title=${title}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getCollectionByTitle = getCollectionByTitle;
/**
 *
 * @param category
 * @returns
 */
const getDiscoverConfig = async () => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.configs}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getDiscoverConfig = getDiscoverConfig;
/**
 *
 * @param category
 * @returns
 */
const getCollectionDetails = async (collectionId) => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.collections}/${collectionId}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getCollectionDetails = getCollectionDetails;
/**
 *
 * @param category
 * @returns
 */
const getGptModels = async () => {
    return new Promise(async (resolve) => {
        await interceptorsAnon_1.cachedNetworkCall
            .get(`${apis_1.APP_CONSTANTS.discover.gptModels}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.getGptModels = getGptModels;
