"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
// Get started screen
const MembershipScreen = () => {
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    //MembershipSheet
};
exports.default = (0, react_1.memo)(MembershipScreen);
