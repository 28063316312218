"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppFormLabel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const _1 = require(".");
const colors_1 = require("../../common/colors");
const theme_1 = require("../../helper/theme");
const AppFormLabel = ({ children, ...rest }) => {
    return ((0, jsx_runtime_1.jsx)(_1.AppText, { color: colors_1.Colors.APP_LABEL, fontWeight: 500, fontSize: (0, theme_1.normalize)(14), lineHeight: (0, theme_1.normalize)(16), 
        // mb={1}
        allowFontScaling: false, ...rest, children: children }));
};
exports.AppFormLabel = AppFormLabel;
