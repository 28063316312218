"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsImageLoading = exports.selectIsLoading = exports.selectDocumentShareLink = exports.selectIsDocumentSharing = exports.selectIsPdfOpen = exports.selectIsDocumentConversationOpen = exports.selectIsDocumentLoaded = exports.selectDocumentStreaming = exports.selectDocumentImageUrl = exports.selectDocumentSummary = void 0;
const selectDocumentSummary = (state) => state.documents.summary;
exports.selectDocumentSummary = selectDocumentSummary;
const selectDocumentImageUrl = (state) => state.documents.imageUrl;
exports.selectDocumentImageUrl = selectDocumentImageUrl;
const selectDocumentStreaming = (state) => state.documents.streaming;
exports.selectDocumentStreaming = selectDocumentStreaming;
const selectIsDocumentLoaded = (state) => state.documents.isDocumentLoaded;
exports.selectIsDocumentLoaded = selectIsDocumentLoaded;
const selectIsDocumentConversationOpen = (state) => (state.documents.isConversationOpen);
exports.selectIsDocumentConversationOpen = selectIsDocumentConversationOpen;
const selectIsPdfOpen = (state) => state.documents.isPdfOpen;
exports.selectIsPdfOpen = selectIsPdfOpen;
const selectIsDocumentSharing = (state) => state.documents.isSharing;
exports.selectIsDocumentSharing = selectIsDocumentSharing;
const selectDocumentShareLink = (state) => state.documents.shareUrl;
exports.selectDocumentShareLink = selectDocumentShareLink;
const selectIsLoading = (state) => state.documents.isLoading;
exports.selectIsLoading = selectIsLoading;
const selectIsImageLoading = (state) => state.documents.isImageRegenerating;
exports.selectIsImageLoading = selectIsImageLoading;
