"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spacing = void 0;
exports.Spacing = {
    SPACING_XS: 4,
    SPACING_SM: 6,
    SPACING_MD: 8,
    SPACING_LG: 12,
    SPACING_XL: 16,
    SPACING_3XL: 24,
    SPACING_5XL: 40,
    SPACING_6XL: 48,
};
