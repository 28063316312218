"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
function ShareButton({ style, ...restProps }) {
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { style: typeof style === 'function'
            ? (state) => [styles.button, style(state)]
            : [styles.button, style], ...restProps, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faShareNodes, color: 'white', size: 24 }) }));
}
exports.default = ShareButton;
;
const styles = react_native_1.StyleSheet.create({
    button: {
        width: 32,
        height: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
