"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const native_base_1 = require("native-base");
const react_1 = require("react");
const useDevice_1 = require("../../hooks/useDevice");
const colors_1 = require("../../common/colors");
/**
 *
 * @param param0
 * @returns
 */
const SheetCloseButton = ({ onClose }) => {
    const { isWebDevice } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(native_base_1.HStack, { position: "absolute", top: isWebDevice ? 10 : 3, right: isWebDevice ? 5 : 3, zIndex: 1, children: (0, jsx_runtime_1.jsx)(native_base_1.IconButton, { onPress: onClose, background: colors_1.Colors.DARK_500, padding: 1, borderRadius: 32, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faClose, size: 20, color: colors_1.Colors.white }) }) }));
};
exports.default = (0, react_1.memo)(SheetCloseButton);
