"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const AppText_1 = require("../../../../component/AppText");
const colors_1 = require("../../../../common/colors");
const spacing_1 = require("../../../../common/spacing");
function MenuItem({ icon, action, children }) {
    return ((0, jsx_runtime_1.jsxs)(native_base_1.Menu.Item, { onPress: action, style: styles.item, children: [icon, (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.text, children: children })] }));
}
exports.default = MenuItem;
;
const styles = react_native_1.StyleSheet.create({
    item: {
        backgroundColor: 'transparent',
        gap: spacing_1.Spacing.SPACING_MD,
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 4,
    },
    text: {
        color: colors_1.Colors.DARK_200,
        fontWeight: '500',
        lineHeight: 20,
    }
});
