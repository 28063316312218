"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
const AppText_1 = require("../AppText");
const colors_1 = require("../../common/colors");
function PDFButton({ url, active, onPress, style, ...restProps }) {
    const [pressed, setIsPressed] = (0, react_1.useState)(false);
    const handleOnPress = (0, react_1.useCallback)((event) => {
        setIsPressed(!pressed);
        if (onPress) {
            onPress(event);
        }
        else if (url) {
            react_native_1.Linking.openURL(url);
        }
    }, [pressed, onPress, url]);
    const buttonStyle = (0, react_1.useMemo)(() => ([styles.button, (active ?? pressed) && styles.buttonPressed]), [active, pressed]);
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: handleOnPress, style: typeof style === 'function'
            ? (state) => [...buttonStyle, style(state)]
            : [...buttonStyle, style], ...restProps, children: (0, jsx_runtime_1.jsxs)(AppText_1.AppText, { style: [styles.text, (active ?? pressed) && styles.textPressed], children: [(0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { color: (active ?? pressed) ? colors_1.Colors.PRIMARY_300 : 'white', icon: pro_regular_svg_icons_1.faFileLines }), ' PDF'] }) }));
}
exports.default = PDFButton;
;
const styles = react_native_1.StyleSheet.create({
    button: {
        backgroundColor: 'rgba(255, 255, 255, 0.14)',
        width: 116,
        height: 32,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 30,
    },
    buttonPressed: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: colors_1.Colors.PRIMARY_300,
        borderRadius: 30,
    },
    text: {
        color: 'white',
        fontSize: 16,
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
    },
    textPressed: {
        color: colors_1.Colors.PRIMARY_300,
    }
});
