"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessege = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const colors_1 = require("../../common/colors");
function ErrorMessege({ msg, isError }) {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isError && ((0, jsx_runtime_1.jsx)(native_base_1.Text, { color: colors_1.Colors.red, padding: 1, children: msg })) }));
}
exports.ErrorMessege = ErrorMessege;
