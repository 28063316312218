"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const AppText_1 = require("../../component/AppText");
const colors_1 = require("../../common/colors");
function DiscoverFooter() {
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.container, children: [(0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.links, children: [(0, jsx_runtime_1.jsx)(react_native_1.Pressable, { children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.link, children: "Cookies Policy" }) }), (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.link, children: "Terms" }) }), (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.link, children: "Privacy" }) })] }), (0, jsx_runtime_1.jsxs)(AppText_1.AppText, { style: styles.text, children: ["2024 \u00A9 TalkToo \u00B7 All rights reserved \u00B7 Made by ", (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: styles.link, children: "makr.ai" })] })] }));
}
exports.default = DiscoverFooter;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        gap: 32,
        alignItems: 'center',
    },
    links: {
        flexDirection: 'row',
        gap: 32,
    },
    link: {
        color: colors_1.Colors.PRIMARY_300,
        textDecorationLine: 'underline',
        lineHeight: 16,
    },
    text: {
        color: colors_1.Colors.DARK_200,
    },
});
