"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
function MaskedView({ maskElement, children, ...restProps }) {
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { ...restProps, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.background, children: maskElement }), children] }));
}
exports.default = MaskedView;
;
const styles = react_native_1.StyleSheet.create({
    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 9999,
    }
});
