"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const native_base_1 = require("native-base");
const react_1 = require("react");
const useDevice_1 = require("../../hooks/useDevice");
const colors_1 = require("../../common/colors");
const theme_1 = require("../../helper/theme");
const AppText_1 = require("../AppText");
const CharacterWarn = (props) => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsxs)(native_base_1.HStack, { width: !(isWebDevice && isMobileScreen) ? "90%" : undefined, alignItems: "center", alignSelf: "center", padding: isWebDevice && isMobileScreen ? 3 : 2, marginX: 23, space: isWebDevice && isMobileScreen ? 3 : 2, borderWidth: 1, bg: "#1a1a1e", borderColor: "#2A2A2F", borderRadius: 5, ...props, children: [(0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faDiamondExclamation, size: 16, color: colors_1.Colors.white, style: {
                    flexShrink: 0,
                } }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.DARK_100, textAlign: "left", alignSelf: "center", fontSize: (0, theme_1.normalize)(11), fontWeight: 400, lineHeight: isWebDevice && isMobileScreen ? 20 : undefined, allowFontScaling: false, children: isWebDevice && isMobileScreen ? ("Observe copyright laws and honor the rights of fellow creators.") : ("Observe copyright laws. Do not infringe on the rights of creators.") })] }));
};
exports.default = (0, react_1.memo)(CharacterWarn);
