"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const object_1 = require("../utils/object");
Object.defineProperty(Object.prototype, 'isContainedIn', {
    value: function (object) {
        return (0, object_1.objectIsContainedIn)(this, object);
    },
    enumerable: false,
});
Object.defineProperty(Object.prototype, 'searchByKey', {
    value: function (search) {
        return (0, object_1.searchByKey)(this, search);
    },
    enumerable: false,
});
Object.defineProperty(Object.prototype, 'searchByValue', {
    value: function (search) {
        return (0, object_1.searchByValue)(this, search);
    },
    enumerable: false,
});
