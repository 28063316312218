"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const store_1 = require("../store");
const rootNavigation_1 = require("../navigation/rootNavigation");
const thunks_1 = require("../store/thunks");
const useAppMessage_1 = require("./useAppMessage");
const error_1 = require("../common/error");
const constants_1 = require("../common/constants");
function useRequest() {
    const dispatch = (0, store_1.useAppDispatch)();
    const { showNotification } = (0, useAppMessage_1.useAppMessage)();
    const ApiErrorHandler = (0, react_1.useCallback)((error) => {
        switch (error.reason) {
            case 'Not Found':
                showNotification('Error', constants_1.NOT_FOUND_MESSAGE, 'error');
                break;
            default:
                showNotification('Error', error.message, 'error');
        }
        switch (error.module) {
            case 'Summary':
                (0, rootNavigation_1.navigate)('Home', { screen: 'Documents' });
                break;
        }
        ;
        return null;
    }, []);
    const RequestErrorHandler = (0, react_1.useCallback)((error) => {
        switch (error.reason) {
            case 'Session Out':
                showNotification('Error', constants_1.SESSION_OUT_MESSAGE, 'error');
                dispatch((0, thunks_1.logOut)());
                break;
            default:
                showNotification('Error', constants_1.DEFAULT_ERROR_MESSAGE, 'error');
                console.error(error);
        }
        switch (error.module) {
            case 'Summary':
                (0, rootNavigation_1.navigate)('Home', { screen: 'Documents' });
                break;
        }
        ;
        return null;
    }, []);
    const customErrorActionHandler = (0, react_1.useCallback)((error, handledErrorType, customErrorActions, defaultAction) => {
        const customErrorAction = customErrorActions[handledErrorType.name];
        if (customErrorAction) {
            customErrorAction(error);
        }
        else {
            defaultAction(error);
        }
        ;
        return null;
    }, []);
    const customRequest = (0, react_1.useCallback)((request, customErrorActions, ...params) => request(...Array.from(params))
        .then((result) => {
        switch (true) {
            case result instanceof error_1.APIError:
                return customErrorActionHandler(result, error_1.APIError, customErrorActions, ApiErrorHandler);
            case result instanceof error_1.RequestError:
                return customErrorActionHandler(result, error_1.RequestError, customErrorActions, RequestErrorHandler);
            default:
                return result;
        }
    }), [
        customErrorActionHandler,
        ApiErrorHandler,
        RequestErrorHandler,
    ]);
    const request = (0, react_1.useCallback)((request, ...params) => request(...Array.from(params))
        .then((result) => {
        switch (true) {
            case result instanceof error_1.APIError:
                return ApiErrorHandler(result);
            case result instanceof error_1.RequestError:
                return RequestErrorHandler(result);
            default:
                return result;
        }
    }), [ApiErrorHandler, RequestErrorHandler]);
    return {
        request,
        customRequest,
    };
}
exports.default = useRequest;
;
