"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectCharacters = exports.getCharactersSlice = exports.getCategoryCharactersAsync = void 0;
const initial_state_1 = require("../common/initial-state");
const create_async_slice_1 = require("./helper/create-async-slice");
const thunks_1 = require("../store/thunks");
const toolkit_1 = require("@reduxjs/toolkit");
const character_1 = require("../services/character");
exports.getCategoryCharactersAsync = (0, toolkit_1.createAsyncThunk)("character/category/get", async ({ category, }, { rejectWithValue }) => {
    console.log("getCategoryCharactersAsync Thunk test...");
    const characters = await (0, character_1.getCharacterList)(category);
    return characters?.data || [];
});
// Slice
exports.getCharactersSlice = (0, create_async_slice_1.createAsyncSlice)({
    name: "character/get",
    initialState: (0, initial_state_1.getInitialState)(),
    reducers: {},
    thunks: [exports.getCategoryCharactersAsync],
    extraReducers: [
        [thunks_1.logOut.fulfilled, () => (0, initial_state_1.getInitialState)()]
    ],
});
// Selectors
const selectCharacters = (state) => state.characters;
exports.selectCharacters = selectCharacters;
