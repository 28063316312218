"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisabledGradientButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_native_1 = require("react-native");
const colors_1 = require("../../common/colors");
function DisabledGradientButton({ text, customStyles, loading, onPress, }) {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { onPress: onPress, 
            // underlayColor="white"
            activeOpacity: 0.9, style: { width: "100%" }, accessible: false, children: (0, jsx_runtime_1.jsx)(native_base_1.View, { style: [styles.linearGradient, customStyles?.button], children: (0, jsx_runtime_1.jsx)(react_native_1.Text, { adjustsFontSizeToFit: true, style: [styles.linearGradientText, customStyles?.text], children: text }) }) }) }));
}
exports.DisabledGradientButton = DisabledGradientButton;
const styles = react_native_1.StyleSheet.create({
    linearGradient: {
        display: "flex",
        height: 45,
        width: "100%",
        borderRadius: 20,
        backgroundColor: colors_1.Colors.DARK_600, //create character
    },
    linearGradientText: {
        textAlign: "center",
        margin: 0,
        paddingVertical: 12.5,
        fontSize: 16,
        color: colors_1.Colors.DARK_400,
        fontWeight: "bold",
    },
});
