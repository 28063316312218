"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.takeActiveSubscription = exports.takeActiveEntitlement = void 0;
const takeActiveEntitlement = (entitlements) => (Object.entries(entitlements).reduce((activeEntitlement, [currentPlan, currentEntitlement]) => (currentEntitlement.expires_date > new Date().toISOString() && (!activeEntitlement || currentEntitlement.expires_date > activeEntitlement.expires_date)
    ? { ...currentEntitlement, plan: currentPlan }
    : activeEntitlement), null));
exports.takeActiveEntitlement = takeActiveEntitlement;
const takeActiveSubscription = (subscriptions) => (subscriptions.reduce((activeSubscription, currentSubscription) => (!activeSubscription || currentSubscription.expires_date > activeSubscription.expires_date
    ? currentSubscription
    : activeSubscription), null));
exports.takeActiveSubscription = takeActiveSubscription;
