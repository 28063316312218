"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const useDevice_1 = require("../../hooks/useDevice");
const AppText_1 = require("../AppText");
const AppIcons_1 = require("../AppIcons");
const DEFAULT_ICON_CONTROL_SIZE = 22;
const DEFAULT_ICON_CONTROL_SIZE_MOBILE = 24;
function Collapsible({ title, titleStyle, collapseControlSize, children, }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    const [isCollapsed, setIsCollapsed] = (0, react_1.useState)(true);
    const [collapseHeight, setCollapseHeight] = (0, react_1.useState)();
    const { current: collapseAnimationValue } = (0, react_1.useRef)(new react_native_1.Animated.Value(0));
    const { current: expandAnimation } = (0, react_1.useRef)(react_native_1.Animated.timing(collapseAnimationValue, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true
    }));
    const { current: collapseAnimation } = (0, react_1.useRef)(react_native_1.Animated.timing(collapseAnimationValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true
    }));
    const createCollapseAnimation = (height) => {
        // height has its maximum value on first layout. For the second and on, it assumes 0 value until animated interpolate be created
        if (!collapseHeight && height) {
            setCollapseHeight(collapseAnimationValue.interpolate({
                inputRange: [0, 1],
                outputRange: [0, height],
            }));
        }
    };
    const toggleCollapse = () => {
        if (isCollapsed) {
            expandAnimation.start();
        }
        else {
            collapseAnimation.start();
        }
        setIsCollapsed(!isCollapsed);
    };
    const handleAnswerLayout = ({ nativeEvent: { layout: { height } } }) => {
        createCollapseAnimation(height);
    };
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { children: [(0, jsx_runtime_1.jsx)(react_native_1.TouchableWithoutFeedback, { onPress: toggleCollapse, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.question, children: [(0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [
                                styles.text,
                                isMobileScreen && styles.textMobile,
                                titleStyle
                            ], children: title }), isCollapsed ? ((0, jsx_runtime_1.jsx)(AppIcons_1.PlusCircle, { width: collapseControlSize || isMobileScreen
                                ? DEFAULT_ICON_CONTROL_SIZE_MOBILE
                                : DEFAULT_ICON_CONTROL_SIZE, height: collapseControlSize || isMobileScreen
                                ? DEFAULT_ICON_CONTROL_SIZE_MOBILE
                                : DEFAULT_ICON_CONTROL_SIZE })) : ((0, jsx_runtime_1.jsx)(AppIcons_1.MinusCircle, { width: collapseControlSize || isMobileScreen
                                ? DEFAULT_ICON_CONTROL_SIZE_MOBILE
                                : DEFAULT_ICON_CONTROL_SIZE, height: collapseControlSize || isMobileScreen
                                ? DEFAULT_ICON_CONTROL_SIZE_MOBILE
                                : DEFAULT_ICON_CONTROL_SIZE }))] }) }), (0, jsx_runtime_1.jsx)(react_native_1.Animated.View, { style: [
                    styles.text,
                    isMobileScreen && styles.textMobile,
                    {
                        height: collapseHeight,
                        overflow: 'hidden',
                    },
                ], onLayout: handleAnswerLayout, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.answerContentContainer, children: children }) })] }));
}
exports.default = Collapsible;
const styles = react_native_1.StyleSheet.create({
    question: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    text: {
        maxWidth: `calc(100% - 20px - ${DEFAULT_ICON_CONTROL_SIZE}px)`,
    },
    textMobile: {
        maxWidth: `calc(100% - 24px - ${DEFAULT_ICON_CONTROL_SIZE_MOBILE}px)`,
    },
    answerContentContainer: {
        marginTop: 8,
    },
});
