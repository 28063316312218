"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const AppText_1 = require("../AppText");
const colors_1 = require("../../common/colors");
const INITIAL_OFFSET = 4;
const BORDER_WIDTH = 1;
const TABS_GAP = 8;
const INDICATOR_ANIMATION_TIMING = 300;
function TabSelect({ tabs, initialSelected, onSelect, tabsStyles }) {
    const [selectedIndex, setSelectedIndex] = (0, react_1.useState)(initialSelected ?? 0);
    const [tabWidth, setTabWidth] = (0, react_1.useState)(0);
    const indicatorAnimatedPositionRef = (0, react_1.useRef)(new react_native_1.Animated.Value(0));
    const getIndicatorOffset = (0, react_1.useCallback)((tabIndex) => (tabIndex * (tabWidth + TABS_GAP) + INITIAL_OFFSET - 1
    // "-1" used for fix position offset on browser (tested on Chrome)
    ), [tabWidth]);
    const moveIndicator = (0, react_1.useCallback)((newPosition) => {
        if (indicatorAnimatedPositionRef.current) {
            react_native_1.Animated.timing(indicatorAnimatedPositionRef.current, {
                toValue: newPosition,
                duration: INDICATOR_ANIMATION_TIMING,
                useNativeDriver: true,
            }).start();
        }
    }, []);
    (0, react_1.useEffect)(() => {
        moveIndicator(getIndicatorOffset(initialSelected ?? 0));
    }, [getIndicatorOffset]);
    const handleTabLayout = (0, react_1.useCallback)(({ nativeEvent: { layout: { width } } }) => {
        setTabWidth(width);
    }, []);
    const handleSelectTab = (tabIndex) => {
        setSelectedIndex(tabIndex);
        moveIndicator(getIndicatorOffset(tabIndex));
        onSelect(tabIndex);
    };
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.container, tabsStyles], children: [tabs.map(({ label, badge }, index) => ((0, jsx_runtime_1.jsx)(react_native_1.TouchableWithoutFeedback, { onPress: () => handleSelectTab(index), children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { onLayout: handleTabLayout, style: styles.tabContainer, children: [(0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: selectedIndex === index ? 'white' : colors_1.Colors.DARK_100, fontSize: 14, fontWeight: 600, lineHeight: 20, children: label }), badge && ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: [styles.badge, {
                                    backgroundColor: badge.style.backgroundColor,
                                    borderColor: badge.style.color,
                                }], children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: badge.style.borderColor, fontSize: 12, fontWeight: 500, lineHeight: 18, children: badge.text }) }))] }) }, index))), (0, jsx_runtime_1.jsx)(react_native_1.Animated.View, { style: [styles.indicator, {
                        left: indicatorAnimatedPositionRef.current,
                        width: tabWidth,
                    }] })] }));
}
exports.default = TabSelect;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: '#3A3A3A',
        width: 368,
        maxWidth: '100%',
        gap: TABS_GAP,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'center',
        padding: INITIAL_OFFSET - BORDER_WIDTH,
        borderColor: '#49494B',
        borderWidth: BORDER_WIDTH,
        borderRadius: 8,
    },
    tabContainer: {
        width: 176,
        maxWidth: `calc(50% - ${TABS_GAP / 2}px)`,
        gap: TABS_GAP,
        zIndex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 7,
    },
    badge: {
        paddingVertical: 1,
        paddingHorizontal: 7,
        borderWidth: 1,
        borderRadius: 12,
    },
    indicator: {
        backgroundColor: colors_1.Colors.DARK_600,
        width: 176,
        height: 36,
        position: 'absolute',
        borderRadius: 6,
    },
});
