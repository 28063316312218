"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputForm = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_native_1 = require("react-native");
const react_native_svg_1 = require("react-native-svg");
const useDevice_1 = require("../../hooks/useDevice");
const icons_1 = require("../../common/icons");
const colors_1 = require("../../common/colors");
const AppInput_1 = require("../AppInput");
function InputForm({ id, placeholder, value, onChangeText, isInfo, placeHolderColor, defaultValue, onReset, onBlur, autoCapitalize, }) {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(AppInput_1.AppInput, { width: "100%", height: isWebDevice && isMobileScreen ? 44 : 45, size: "lg", placeholder: placeholder, placeholderTextColor: placeHolderColor, value: value, defaultValue: defaultValue, onChangeText: (value) => onChangeText(value, id), secureTextEntry: id === "password" ? true : false, onReset: onReset, autoCapitalize: autoCapitalize, onBlur: onBlur, _hover: {
                    borderColor: colors_1.Colors.PRIMARY_100,
                } }), isInfo && ((0, jsx_runtime_1.jsx)(native_base_1.HStack, { alignSelf: "flex-end", mt: 2, children: (0, jsx_runtime_1.jsx)(native_base_1.Popover, { trigger: (triggerProps) => {
                        return ((0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { ...triggerProps, children: (0, jsx_runtime_1.jsx)(react_native_svg_1.SvgCss, { xml: icons_1.INFO_ICON }) }));
                    }, children: (0, jsx_runtime_1.jsxs)(native_base_1.Popover.Content, { accessibilityLabel: "Password hint", w: "56", children: [(0, jsx_runtime_1.jsx)(native_base_1.Popover.Arrow, {}), (0, jsx_runtime_1.jsx)(native_base_1.Popover.Body, { children: "Pasaword should be atleast one character and one number and special symbol." })] }) }) }))] }));
}
exports.InputForm = InputForm;
