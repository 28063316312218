"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const GradientButton_1 = require("../GradientButton");
function CategoryButton({ startColor, endColor, icon, onPress }) {
    return ((0, jsx_runtime_1.jsx)(GradientButton_1.HorizontalGradient, { startColor: startColor, endColor: endColor, icon: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: icon, size: 20, color: "white" }), onPress: onPress, buttonStyle: styles.button }));
}
exports.default = CategoryButton;
;
const styles = react_native_1.StyleSheet.create({
    button: {
        minWidth: 0,
        maxWidth: 48,
        minHeight: 0,
        padding: 14,
        borderRadius: 24,
    },
});
