"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectConversation = exports.setSelectedConversation = exports.conversationSlice = exports.updateConversationsAsync = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initial_state_1 = require("../common/initial-state");
const thunks_1 = require("../store/thunks");
const conversation_1 = require("../services/conversation");
const create_async_slice_1 = require("./create-async-slice");
exports.updateConversationsAsync = (0, toolkit_1.createAsyncThunk)("service/conversation", async ({ id, params }) => {
    const res = await (0, conversation_1.updateConversation)(id, params);
    return res;
});
exports.conversationSlice = (0, create_async_slice_1.createAsyncSlice)({
    name: "conversation",
    initialState: (0, initial_state_1.getInitialState)(),
    reducers: {
        setSelectedConversation: (state, { payload } //{ data: Conversations }
        ) => {
            // console.log("state payload..", payload);
            state.member = payload;
        },
    },
    thunks: [exports.updateConversationsAsync],
    extraReducers: [
        [thunks_1.logOut.fulfilled, () => (0, initial_state_1.getInitialState)()]
    ],
});
// Actions
exports.setSelectedConversation = exports.conversationSlice.actions.setSelectedConversation;
// Selectors
const selectConversation = (state) => {
    // console.log("state..", state);
    return state.selectedConversation;
};
exports.selectConversation = selectConversation;
