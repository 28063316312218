"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const rootNavigation_1 = require("../../navigation/rootNavigation");
const useDevice_1 = require("../../hooks/useDevice");
function GoBackButton({ style, ...restProps }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: rootNavigation_1.goBack, style: typeof style === 'function'
            ? (state) => [
                styles.button,
                isMobileScreen && styles.buttonMobile,
                style(state)
            ]
            : [styles.button, isMobileScreen && styles.buttonMobile, style], ...restProps, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faArrowLeft, color: "white", size: isMobileScreen ? 24 : 22 }) }));
}
exports.default = GoBackButton;
const styles = react_native_1.StyleSheet.create({
    button: {
        width: 42,
        height: 42,
        position: 'relative',
        top: 'auto',
        left: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonMobile: {
        width: 32,
        height: 32,
        position: 'relative',
        top: 'auto',
        left: 'auto',
    },
});
