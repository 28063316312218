"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const useAnalytics_1 = require("../../hooks/useAnalytics");
const useAppMessage_1 = require("../../hooks/useAppMessage");
const AppText_1 = require("../AppText");
const store_1 = require("../../store");
const appState_1 = require("../../slices/appState");
const firebase_1 = require("../../auth/firebase");
const colors_1 = require("../../common/colors");
const LoginGoogle = ({ loginSuccessHandler, style }) => {
    const dispatch = (0, store_1.useAppDispatch)();
    const { logEvent } = (0, useAnalytics_1.useAnalytics)();
    const { showNotification } = (0, useAppMessage_1.useAppMessage)();
    const onGoogleButtonPress = (0, react_1.useCallback)(async () => {
        try {
            const { user } = await (0, firebase_1.signInPopUp)(firebase_1.googleProvider);
            if (!user) {
                return;
            }
            await logEvent("signin_google", { uid: user.uid });
            await loginSuccessHandler(user);
        }
        catch (error) {
            dispatch((0, appState_1.setIsLogging)(false));
            setTimeout(() => {
                showNotification("Sign In error", `${error}. Error while google sign in using Google`, "error");
            }, 1000);
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_native_1.TouchableOpacity, { style: [styles.googleButton, style], onPress: onGoogleButtonPress, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.safeArea, children: [(0, jsx_runtime_1.jsx)(react_native_1.Image, { style: styles.googleIcon, source: {
                            uri: "https://i.ibb.co/j82DCcR/search.png",
                        } }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [styles.googleButtonText], children: "Continue with Google" })] }) }) }));
};
const styles = react_native_1.StyleSheet.create({
    googleButton: {
        backgroundColor: "white",
        // width: '100%',
        borderRadius: 35,
        paddingVertical: 12,
        alignItems: 'center',
    },
    safeArea: {
        width: '100%',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
    },
    googleButtonText: {
        color: colors_1.Colors.DARK_700,
        fontSize: 16,
        fontWeight: "600",
        marginLeft: "4%",
    },
    googleIcon: {
        height: 18,
        width: 18,
    },
});
exports.default = LoginGoogle;
