"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_tab_view_1 = require("react-native-tab-view");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const useDevice_1 = require("../../hooks/useDevice");
const AppText_1 = require("../../component/AppText");
const colors_1 = require("../../common/colors");
const TabBarWithIcon = (props) => {
    const { navigationState, position } = props;
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    const renderIcon = (0, react_1.useCallback)(({ route, focused }) => {
        const iconColor = focused ? colors_1.Colors.white : colors_1.Colors.DARK_GRAY; // Customize icon color based on focus
        if (route.icon) {
            return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: isWebDevice && styles.iconWeb, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: route.icon, size: isWebDevice ? 30 : 24, color: iconColor, style: { marginBottom: !isWebDevice ? 5 : 0 } }) }));
        }
    }, [isWebDevice]);
    return ((0, jsx_runtime_1.jsx)(react_native_tab_view_1.TabBar, { ...props, style: {
            backgroundColor: colors_1.Colors.DARK_700,
        }, tabStyle: {
            minHeight: 42,
            justifyContent: 'flex-start',
            padding: 0,
        }, indicatorStyle: {
            backgroundColor: colors_1.Colors.PRIMARY_300,
            height: (isWebDevice
                ? isMobileScreen
                    ? 3
                    : 2
                : undefined),
            borderTopLeftRadius: !(isWebDevice && !isMobileScreen) ? 16 : 0,
            borderTopRightRadius: !(isWebDevice && !isMobileScreen) ? 16 : 0,
        }, renderLabel: ({ route, focused, color }) => ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.tabItem, children: [!isWebDevice && renderIcon({ route, focused, color }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: focused
                        ? isWebDevice
                            ? 'white'
                            : colors_1.Colors.white
                        : isWebDevice
                            ? colors_1.Colors.DARK_300
                            : colors_1.Colors.DARK_GRAY, fontSize: isWebDevice ? 14 : 12, fontWeight: focused ? 500 : 400, lineHeight: isWebDevice ?
                        isMobileScreen
                            ? 32
                            : 'normal'
                        : undefined, minWidth: 94.64, children: route.title })] })), renderIcon: !isWebDevice ? renderIcon : null }));
};
const styles = react_native_1.StyleSheet.create({
    tabItem: {
        gap: 8,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
        marginTop: 7,
    },
    iconWeb: {
        width: 32,
        height: 32,
        justifyContent: 'center',
        alignItems: 'center',
    }
});
exports.default = TabBarWithIcon;
