"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const useDevice_1 = require("../../hooks/useDevice");
const AppText_1 = require("../../component/AppText");
const colors_1 = require("../../common/colors");
function Quotation({ children }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.container, children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [styles.text, isMobileScreen && styles.textMobile], children: children }) }));
}
exports.default = Quotation;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        paddingVertical: 8,
        paddingHorizontal: 32,
        borderLeftWidth: 3,
        borderLeftColor: colors_1.Colors.PRIMARY_300,
    },
    text: {
        color: colors_1.Colors.DARK_100,
        fontSize: 20,
        fontStyle: 'italic',
        lineHeight: 34,
    },
    textMobile: {
        fontSize: 18,
    },
});
