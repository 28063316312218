"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDaysBetweenDates = void 0;
const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const calculateDaysBetweenDates = (dateA, dateB) => {
    const normalizedDateA = typeof dateA === 'number' ? dateA : dateA.getTime();
    const normalizedDateB = typeof dateB === 'number' ? dateB : dateB.getTime();
    const daysA = Math.floor(normalizedDateA / DAY);
    const daysB = Math.floor(normalizedDateB / DAY);
    return Math.abs(daysA - daysB);
};
exports.calculateDaysBetweenDates = calculateDaysBetweenDates;
