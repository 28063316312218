"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnauthorizedRequest = exports.RequestError = exports.AuthenticationError = exports.APIError = exports.UnreachableOperation = void 0;
const axios_1 = require("axios");
class UnreachableOperation extends Error {
}
exports.UnreachableOperation = UnreachableOperation;
;
class APIError extends Error {
    reason;
    module;
    constructor(message, info) {
        super(message);
        this.reason = info?.reason;
        this.module = info?.module;
    }
}
exports.APIError = APIError;
;
class AuthenticationError extends Error {
    constructor(message = 'Authentication Error', options) {
        super(message, options);
    }
    ;
}
exports.AuthenticationError = AuthenticationError;
;
class RequestError extends axios_1.AxiosError {
    reason;
    module;
    constructor({ message, code, config, request, response }, info) {
        super(message, code, config, request, response);
        this.reason = info?.reason;
        this.module = info?.module;
    }
    ;
}
exports.RequestError = RequestError;
;
class UnauthorizedRequest extends axios_1.AxiosError {
    constructor({ message, code, config, request, response }) {
        super(message, code, config, request, response);
    }
    ;
}
exports.UnauthorizedRequest = UnauthorizedRequest;
;
exports.default = {
    APIError,
    RequestError,
};
