"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGpt = void 0;
const react_1 = require("react");
const discover_1 = require("../services/discover");
const useGpt = () => {
    const modelObjects = (0, react_1.useMemo)(() => {
        return [
            {
                active: "Y",
                createdAt: "2023-11-28T10:57:34.849Z",
                gptModelValue: "gpt-3.5-turbo-16k",
                id: "1",
                name: "GPT-3.5",
                updatedAt: "2023-11-28T10:57:34.849Z",
            },
            {
                active: "Y",
                createdAt: "2023-11-28T10:58:14.499Z",
                gptModelValue: "gpt-4",
                id: "2",
                name: "GPT-4",
                updatedAt: "2023-11-28T10:58:14.499Z",
            },
            {
                active: "Y",
                createdAt: "2023-11-28T10:58:32.378Z",
                gptModelValue: "gpt-4-0314",
                id: "3",
                name: "GPT-4-0314",
                updatedAt: "2023-11-28T10:58:32.378Z",
            },
            {
                active: "Y",
                createdAt: "2023-11-28T10:58:47.010Z",
                gptModelValue: "gpt-4-0613",
                id: "4",
                name: "GPT-4-0613",
                updatedAt: "2023-11-28T10:58:47.010Z",
            },
            {
                active: "Y",
                createdAt: "2023-11-28T10:59:02.635Z",
                gptModelValue: "gpt-4-1106-preview",
                id: "5",
                name: "GPT-4-1106-preview",
                updatedAt: "2023-11-28T10:59:02.635Z",
            },
        ];
    }, []);
    const DEFAULT_MODEL = "gpt3.5";
    const [models, setModels] = (0, react_1.useState)();
    /**
     *
     * @returns
     */
    const getModels = async () => {
        const modelsJson = await (0, discover_1.getGptModels)();
        console.log("modelsJson:", modelsJson);
        setModels(modelsJson?.data || modelObjects);
        // get models
        return modelsJson?.data || modelObjects;
    };
    (0, react_1.useEffect)(() => {
        getModels();
    }, []);
    return {
        getModels,
        models,
        DEFAULT_MODEL,
    };
};
exports.useGpt = useGpt;
