"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetShareUrl = exports.resetStreaming = exports.resetDocumentImageUrl = exports.resetDocumentSummary = exports.closeDocumentPanels = exports.togglePdfOpen = exports.toggleDocumentConversationOpen = exports.setIsImageRegenerating = exports.setIsLoading = exports.setShareUrl = exports.setIsDocumentSharing = exports.setIsDocumentLoaded = exports.setDocumentStreaming = exports.setDocumentImageUrl = exports.setDocumentSummary = exports.shareDocument = exports.documentSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const document_1 = require("../services/document");
const initialState = {
    isDocumentLoaded: false,
    shareUrl: '',
    isConversationOpen: false,
    isPdfOpen: false,
    isSharing: false,
    isLoading: false,
    isImageRegenerating: false,
};
exports.documentSlice = (0, toolkit_1.createSlice)({
    name: 'document',
    initialState,
    reducers: {
        setDocumentSummary(state, { payload }) {
            state.summary = payload;
        },
        setDocumentImageUrl(state, { payload }) {
            state.imageUrl = payload;
        },
        setDocumentStreaming(state, { payload }) {
            state.streaming = payload;
        },
        setIsDocumentLoaded(state, { payload }) {
            state.isDocumentLoaded = payload;
        },
        setIsDocumentSharing(state, { payload }) {
            state.isSharing = payload;
        },
        setShareUrl(state, { payload }) {
            state.shareUrl = payload;
        },
        setIsLoading(state, { payload }) {
            state.isLoading = payload;
        },
        setIsImageRegenerating(state, { payload }) {
            state.isImageRegenerating = payload;
        },
        toggleDocumentConversationOpen(state) {
            state.isConversationOpen = !state.isConversationOpen;
            state.isPdfOpen = false;
        },
        togglePdfOpen(state) {
            state.isPdfOpen = !state.isPdfOpen;
            state.isConversationOpen = false;
        },
        closeDocumentPanels(state) {
            state.isConversationOpen = false;
            state.isPdfOpen = false;
        },
        resetDocumentSummary(state) {
            state.summary = initialState.summary;
        },
        resetDocumentImageUrl(state) {
            state.imageUrl = initialState.imageUrl;
        },
        resetStreaming(state) {
            state.streaming = initialState.streaming;
        },
        resetShareUrl(state) {
            state.shareUrl = initialState.shareUrl;
        },
    },
    extraReducers(builder) {
        builder.addCase(exports.shareDocument.fulfilled, (state, { payload }) => {
            state.isSharing = true;
            state.shareUrl = payload;
        });
    },
});
exports.shareDocument = (0, toolkit_1.createAsyncThunk)('document/shareDocument', async ({ conversationId, fileId }) => {
    const shareLink = await (0, document_1.getShareLink)(conversationId, fileId);
    if (shareLink instanceof Error) {
        throw shareLink;
    }
    ;
    return shareLink.url;
});
_a = exports.documentSlice.actions, exports.setDocumentSummary = _a.setDocumentSummary, exports.setDocumentImageUrl = _a.setDocumentImageUrl, exports.setDocumentStreaming = _a.setDocumentStreaming, exports.setIsDocumentLoaded = _a.setIsDocumentLoaded, exports.setIsDocumentSharing = _a.setIsDocumentSharing, exports.setShareUrl = _a.setShareUrl, exports.setIsLoading = _a.setIsLoading, exports.setIsImageRegenerating = _a.setIsImageRegenerating, exports.toggleDocumentConversationOpen = _a.toggleDocumentConversationOpen, exports.togglePdfOpen = _a.togglePdfOpen, exports.closeDocumentPanels = _a.closeDocumentPanels, exports.resetDocumentSummary = _a.resetDocumentSummary, exports.resetDocumentImageUrl = _a.resetDocumentImageUrl, exports.resetStreaming = _a.resetStreaming, exports.resetShareUrl = _a.resetShareUrl;
exports.default = exports.documentSlice;
