"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadPromptAsync = exports.sendMessegeAsync = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const messeges_1 = require("../services/messeges");
exports.sendMessegeAsync = (0, toolkit_1.createAsyncThunk)("service/send-msg", async (data) => {
    const res = await (0, messeges_1.sendMessege)(data);
    return res;
});
exports.uploadPromptAsync = (0, toolkit_1.createAsyncThunk)("service/upload-prompt", async (data) => {
    const res = await (0, messeges_1.sendPrompt)(data);
    return res;
});
