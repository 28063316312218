"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const colors_1 = require("../../common/colors");
const AppLoader = ({ isLoading, showLoader, timeoutSec, darkBg, size = 'lg', }) => {
    const [timeoutAvailable, setTimeoutAvailable] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        if (timeoutSec && timeoutSec > 0) {
            setTimeout(() => {
                setTimeoutAvailable(false);
            }, timeoutSec || 10000);
        }
    }, []);
    return isLoading && timeoutAvailable ? ((0, jsx_runtime_1.jsx)(native_base_1.HStack, { space: 8, alignItems: "center", height: "100%", minHeight: 150, width: "100%", opacity: 1, position: "absolute", zIndex: 999, top: 0, backgroundColor: `${darkBg ? colors_1.Colors.DARK_700 : colors_1.Colors.DARK_600}80`, children: showLoader && ((0, jsx_runtime_1.jsx)(native_base_1.Stack, { space: 8, alignItems: "center", width: "100%", children: (0, jsx_runtime_1.jsx)(native_base_1.Spinner, { style: { width: 100 }, size: size, color: colors_1.Colors.PRIMARY_BLUE }) })) })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}));
};
exports.default = AppLoader;
