"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const colors_1 = require("../../common/colors");
const theme_1 = require("../../helper/theme");
const useDevice_1 = require("../../hooks/useDevice");
const AppIcons_1 = require("../AppIcons");
const AppText_1 = require("../AppText");
const react_native_1 = require("react-native");
/**
 * Warning message above chat messages
 * @returns
 */
const MessageWarn = () => {
    const { deviceWidth, isTabOrDesktopDevice } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(native_base_1.HStack, { width: "100%", paddingX: isTabOrDesktopDevice ? 4 : 0, maxWidth: isTabOrDesktopDevice ? (deviceWidth * 68) / 100 : deviceWidth - 15, justifyContent: react_native_1.Platform.OS === 'web' ? 'center' : undefined, alignSelf: isTabOrDesktopDevice ? "center" : "flex-start", mb: react_native_1.Platform.OS === 'web' ? undefined : 1, overflow: "hidden", children: (0, jsx_runtime_1.jsxs)(native_base_1.HStack, { alignSelf: "center", alignItems: "center", padding: react_native_1.Platform.OS === 'web' ? 3 : 2, space: react_native_1.Platform.OS === 'web' ? 1 : 2, borderWidth: 1, bg: "#1a1a1e", borderColor: "#2A2A2F", borderRadius: 5, 
            // marginX={2}
            maxWidth: "100%", children: [(0, jsx_runtime_1.jsx)(AppIcons_1.Warning, {}), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.DARK_TEXT, textAlign: "left", alignSelf: "center", paddingRight: 4, fontSize: (0, theme_1.normalize)(react_native_1.Platform.OS === 'web' ? 11 : 12), fontWeight: 400, allowFontScaling: false, flexWrap: "wrap", maxWidth: "98%", children: "This exchange is for education research and entertainment only. For expert advice please consult a licensed professional." })] }) }));
};
exports.default = (0, react_1.memo)(MessageWarn);
