"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateConversation = exports.getConversation = void 0;
const axios_1 = require("axios");
const interceptorsCached_1 = require("./interceptorsCached");
const error_1 = require("../common/error");
const apis_1 = require("../common/apis");
function getConversation(conversationId) {
    return (interceptorsCached_1.cachedAuthCall
        .get(`${apis_1.APP_CONSTANTS.conversation.index}/${conversationId}`).then((response) => {
        if (!response.success) {
            return new error_1.APIError(response.error?.message);
        }
        return response;
    }).catch((error) => {
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized
                ? 'Session Out' : undefined
        });
    }));
}
exports.getConversation = getConversation;
const updateConversation = async (id, params) => {
    return new Promise(async (resolve, _reject) => {
        await interceptorsCached_1.cachedAuthCall
            .put(`${apis_1.APP_CONSTANTS.conversation.update}/${id}`, params)
            .then(function (response) {
            if (!response.success) {
                resolve(new error_1.APIError(response.error?.message));
            }
            else {
                const json = response;
                resolve(json);
            }
        })
            .catch(function (error) {
            resolve(new error_1.RequestError(error, {
                reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized
                    ? 'Session Out' : undefined
            }));
        });
    });
};
exports.updateConversation = updateConversation;
