"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const native_base_1 = require("native-base");
const react_1 = require("react");
const colors_1 = require("../../common/colors");
const useDevice_1 = require("../../hooks/useDevice");
const useShare_1 = require("../../hooks/useShare");
const ShareProfileButton = () => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    const { shareCharacter } = (0, useShare_1.useShare)();
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(native_base_1.Pressable, { style: {
                height: isWebDevice && isMobileScreen ? 32 : 30,
                width: isWebDevice && isMobileScreen ? 32 : 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }, onPress: shareCharacter, children: (0, jsx_runtime_1.jsx)(native_base_1.Stack, { justifyContent: "center", alignItems: "center", style: { height: "100%" }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faShareNodes, color: colors_1.Colors.white, size: isWebDevice && isMobileScreen ? 24 : 20 }) }) }) }));
};
exports.default = (0, react_1.memo)(ShareProfileButton);
