"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateFileByImage = void 0;
const generateFileByImage = (uri, fileName) => (fetch(uri)
    .then((response) => response.blob())
    .then((blob) => new File([blob], fileName, {
    type: 'image/jpeg',
}))
    .catch((error) => error));
exports.generateFileByImage = generateFileByImage;
