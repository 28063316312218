"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useiPadOrientation = void 0;
const react_1 = require("react");
const react_native_1 = require("react-native");
const useiPadOrientation = () => {
    const [orientation, setOrientation] = (0, react_1.useState)("PORTRAIT");
    const determineAndSetOrientation = () => {
        let width = react_native_1.Dimensions.get("window").width;
        let height = react_native_1.Dimensions.get("window").height;
        if (width < height) {
            setOrientation("PORTRAIT");
        }
        else {
            setOrientation("LANDSCAPE");
        }
    };
    (0, react_1.useEffect)(() => {
        determineAndSetOrientation();
        react_native_1.Dimensions.addEventListener("change", determineAndSetOrientation);
        return () => {
            // Dimensions.removeEventListener("change", determineAndSetOrientation);
        };
    }, []);
    return {
        orientation,
    };
};
exports.useiPadOrientation = useiPadOrientation;
