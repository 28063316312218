"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const _1 = require(".");
const react_native_1 = require("react-native");
function BigButton({ onPress = () => { }, disabled, fullWidth = false, style, containerStyle, textStyle, children = '', ...rest }) {
    return ((0, jsx_runtime_1.jsx)(_1.GradientButton, { text: children, paddingH: 0, fullWidth: fullWidth, disabled: disabled, onPress: onPress, style: containerStyle, customStyles: {
            ...styles,
            button: [{ ...styles.button }, style],
            text: textStyle,
        }, ...rest }));
}
exports.default = BigButton;
const styles = react_native_1.StyleSheet.create({
    button: {
        height: 'auto',
        paddingHorizontal: 24,
        paddingVertical: 16,
    },
    text: {
        paddingVertical: 0,
    }
});
