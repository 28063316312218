"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_native_render_html_1 = __importStar(require("react-native-render-html"));
const user_1 = require("../../store/selectors/user");
const colors_1 = require("../../common/colors");
const useDevice_1 = require("../../hooks/useDevice");
/**
 *
 * @param param0
 * @returns
 */
const ChatHtmlText = ({ message, type, }) => {
    const width = (0, react_native_render_html_1.useContentWidth)();
    const { isTabOrDesktopDevice, deviceWidth, isDesktopDevice, isWebDevice, isMobileScreen, } = (0, useDevice_1.useDevice)();
    const conversation = (0, react_redux_1.useSelector)(user_1.selectConversation);
    const maxContentWidth = (0, react_1.useMemo)(() => {
        return (deviceWidth * (isDesktopDevice ? 80 : 70)) / 100 - 30;
    }, [isTabOrDesktopDevice, isDesktopDevice, deviceWidth]);
    const replaceImageTags = (text) => {
        // Regular expression to match image URLs
        const imageUrlRegex = /\["(https:\/\/[^"]+)"\]/g; ///"((?:https?|ftp):\/\/[^"]+)"/g;
        // Replace image URLs with <img> tags directly in the text
        const replacedText = text.replace(imageUrlRegex, (_, imageUrl) => `<img  width="${maxContentWidth}" src="${imageUrl}" alt="Image" />`);
        return replacedText;
    };
    const htmlSource = (0, react_1.useMemo)(() => {
        const msgHtml = `${message}`;
        const boldHtml = msgHtml
            ?.replace(/(?:\r\n|\r|\n)/g, "<br>")
            ?.replace(/\*{2}(.*?)\*{2}/g, "<b>$1</b>");
        const italicHtml = boldHtml?.replace(/\*{1}(.*?)\*{1}/g, "<b>$1</b>");
        const dynamicImageText = replaceImageTags(italicHtml);
        return {
            html: `
      <div style='color:${colors_1.Colors.white};
        font-size:16px;
        padding-top:${conversation?.chatStyle === 'bubbles'
                ? isWebDevice && isMobileScreen
                    ? 8
                    : 10
                : 0}px;
        padding-bottom:${conversation?.chatStyle === 'bubbles'
                ? isWebDevice && isMobileScreen
                    ? 8
                    : 10
                : 0}px;
        padding-left:${conversation?.chatStyle === 'bubbles'
                ? isWebDevice && isMobileScreen
                    ? 12
                    : 10
                : 0}px;
        padding-right:${conversation?.chatStyle === 'bubbles'
                ? isWebDevice && isMobileScreen
                    ? 12
                    : 10
                : 0}px;
        line-height:${isWebDevice && isMobileScreen
                || conversation?.chatStyle === 'threads' ? 20 : 18}px;
        display:block;
        max-width:${maxContentWidth}px;
        '>
        ${dynamicImageText}
        </div>`,
        };
    }, [message, type, conversation]);
    return ((0, jsx_runtime_1.jsx)(react_native_render_html_1.default, { baseStyle: conversation?.chatStyle === 'threads' ? {} : undefined, contentWidth: width, source: htmlSource, 
        // onHTMLLoaded={}
        defaultTextProps: {
            // selectable: true,
            suppressHighlighting: false,
        } }));
};
exports.default = (0, react_1.memo)(ChatHtmlText);
