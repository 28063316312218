"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSavings = exports.calculateAnnualSavings = void 0;
const constants_1 = require("../common/constants");
const calculateAnnualSavings = () => (1 - constants_1.PREMIUM_ANNUALLY_PRICE / (constants_1.PREMIUM_MONTHLY_PRICE * 12));
exports.calculateAnnualSavings = calculateAnnualSavings;
const calculateSavings = (priceA, priceB, periodRatio = 1) => {
    const higherPrice = priceA > priceB ? priceA : priceB;
    const lesserPrice = higherPrice === priceA ? priceB : priceA;
    return 1 - higherPrice / (lesserPrice * periodRatio);
};
exports.calculateSavings = calculateSavings;
