"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const useDevice_1 = require("../../hooks/useDevice");
const application_host_1 = require("../../common/application-host");
const colors_1 = require("../../common/colors");
/**
 *
 * @param param0
 * @returns
 */
const CollectionCharacters = ({ characters }) => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(native_base_1.Avatar.Group, { _avatar: {
            size: isWebDevice && isMobileScreen ? "34px" : "md",
        }, space: isWebDevice && isMobileScreen ? "-5px" : -6, max: 5, ml: isWebDevice && isMobileScreen ? 0 : 6, children: characters?.slice(0, 5)?.map((character) => {
            return ((0, jsx_runtime_1.jsx)(native_base_1.Avatar, { bg: isWebDevice && isMobileScreen ? undefined : "green.500", source: {
                    uri: (0, application_host_1.getImageUrl)(character),
                }, borderColor: isWebDevice && isMobileScreen ? colors_1.Colors.DARK_700 : colors_1.Colors.DARK_600, borderWidth: isWebDevice && isMobileScreen ? 1 : undefined, children: "IS" }, `${character?.id}_collection-group`));
        }) }));
};
exports.default = CollectionCharacters;
