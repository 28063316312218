"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const native_1 = require("@react-navigation/native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const rootNavigation_1 = require("./rootNavigation");
const HeaderSearchButtons_1 = require("./HeaderSearchButtons");
const AppText_1 = require("../component/AppText");
function DiscoverHeader({ drawer, style, ...restProps }) {
    const buttons = [
        (0, jsx_runtime_1.jsx)(HeaderSearchButtons_1.HeaderSearchButton, {})
    ];
    if (drawer) {
        buttons.reverse();
    }
    ;
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.header, style], ...restProps, children: [(0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { onPress: () => (0, rootNavigation_1.navigate)('Discover'), style: styles.home, children: [(0, jsx_runtime_1.jsx)(react_native_1.View, { style: styles.logoContainer, children: (0, jsx_runtime_1.jsx)(react_native_1.Image, { source: require('../assets/icons/logo.png'), style: styles.logo }) }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: '#EDF2F4', fontSize: 24, fontWeight: 700, children: "talktoo.ai" })] }), (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.buttons, { flexGrow: drawer ? 1 : 2 }], children: [buttons, (0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => { (0, rootNavigation_1.dispatch)(native_1.DrawerActions.toggleDrawer()); }, style: styles.menu, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: drawer ? pro_light_svg_icons_1.faXmark : pro_light_svg_icons_1.faBars, color: "white", size: drawer ? 26 : 25 }) })] })] }));
}
exports.default = DiscoverHeader;
;
const styles = react_native_1.StyleSheet.create({
    header: {
        height: 72,
        flexDirection: 'row',
    },
    home: {
        gap: 4,
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 3,
    },
    logoContainer: {
        width: 32,
        height: 32,
    },
    logo: {
        width: 26,
        height: 32,
    },
    buttons: {
        gap: 16,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    menu: {
        minWidth: 32,
        minHeight: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
