"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    Discover: '',
    Characters: 'Characters',
    Chats: 'Chats/:characterId?',
    Journeys: 'Journeys',
    Documents: 'Documents',
    Account: 'Account',
};
