"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCharacterProfileAsync = exports.clearConversationAsync = exports.deleteConversationAsync = exports.updateConversationsAsync = exports.getConversationMessagesAsync = exports.getAllConversationsAsync = exports.createPermanentUserAsync = exports.createTmpUserAsync = exports.syncUserAsync = exports.startNewConversationsAsync = exports.categoryListAsync = exports.characterListAsync = exports.showMsgBox = exports.showSearch = exports.showSearchSlice = exports.characterDetails = exports.characterDetailsSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const thunks_1 = require("../store/thunks");
const character_1 = require("../services/character");
const initialState = {
    characterDetails: {
        avtar: "",
        name: "",
    },
};
const searchInitialState = {
    isShow: false,
    isMsgBox: true,
};
exports.characterDetailsSlice = (0, toolkit_1.createSlice)({
    name: "characterDetails",
    initialState: initialState,
    reducers: {
        characterDetails: (state, action) => {
            state.characterDetails = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(thunks_1.logOut.fulfilled, () => exports.characterDetailsSlice.getInitialState());
    },
});
exports.characterDetails = exports.characterDetailsSlice.actions.characterDetails;
exports.showSearchSlice = (0, toolkit_1.createSlice)({
    name: "isShowSearch",
    initialState: searchInitialState,
    reducers: {
        showSearch: (state, action) => {
            state.isShow = action.payload;
        },
        showMsgBox: (state, action) => {
            state.isMsgBox = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(thunks_1.logOut.fulfilled, () => exports.showSearchSlice.getInitialState());
    },
});
_a = exports.showSearchSlice.actions, exports.showSearch = _a.showSearch, exports.showMsgBox = _a.showMsgBox;
exports.characterListAsync = (0, toolkit_1.createAsyncThunk)("service/character-list/category", async (category) => {
    const res = await (0, character_1.getCharacterList)(category);
    return res;
});
exports.categoryListAsync = (0, toolkit_1.createAsyncThunk)("service/category-list", async () => {
    const res = await (0, character_1.getCategoryList)();
    return res;
});
exports.startNewConversationsAsync = (0, toolkit_1.createAsyncThunk)("service/conversation", async (params) => {
    const res = await (0, character_1.getNewConversations)(params);
    return res;
});
exports.syncUserAsync = (0, toolkit_1.createAsyncThunk)("service/syncUser", async (userId) => {
    const res = await (0, character_1.syncUser)(userId);
    console.log(res);
    return res;
});
exports.createTmpUserAsync = (0, toolkit_1.createAsyncThunk)("service/createUser", async () => {
    const res = await (0, character_1.createNewSyncUser)();
    return res;
});
exports.createPermanentUserAsync = (0, toolkit_1.createAsyncThunk)("service/createUser/permanent", async (payload) => (0, character_1.createExistingUser)(payload));
exports.getAllConversationsAsync = (0, toolkit_1.createAsyncThunk)("service/allconversations", async (userId) => {
    const res = await (0, character_1.getAllConversations)(userId);
    return res;
});
exports.getConversationMessagesAsync = (0, toolkit_1.createAsyncThunk)("service/conversations", async (id) => {
    const res = await (0, character_1.getConversationMessages)(id);
    return res;
});
exports.updateConversationsAsync = (0, toolkit_1.createAsyncThunk)("service/conversations", async ({ id, params }) => {
    const res = await (0, character_1.updateConversations)(id, params);
    return res;
});
exports.deleteConversationAsync = (0, toolkit_1.createAsyncThunk)("service/conversations/delete", async (id) => {
    const res = await (0, character_1.deleteConversations)(id);
    return res;
});
exports.clearConversationAsync = (0, toolkit_1.createAsyncThunk)("service/conversations/delete/clear", async (id) => {
    const res = await (0, character_1.deleteConversations)(id, true);
    return res;
});
exports.getCharacterProfileAsync = (0, toolkit_1.createAsyncThunk)("service/getProfile", async (id) => {
    const res = await (0, character_1.getCharacterProfile)(id);
    return res;
});
