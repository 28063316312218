"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const colors_1 = require("../../common/colors");
const useDevice_1 = require("../../hooks/useDevice");
const ChatThreadsSheet_1 = __importStar(require("./ChatThreadsSheet"));
/**
 *
 * @param param0
 * @returns
 */
const ChatThreadsHeaderButton = ({}) => {
    const { isTabOrDesktopDevice } = (0, useDevice_1.useDevice)();
    const [menuOpen, setMenuOpen] = (0, react_1.useState)(false);
    const { isOpen: isOpenChatThreads, onOpen: onOpenChatThreads, onClose: onCloseChatStyle, } = (0, native_base_1.useDisclose)();
    const menuRef = (0, react_1.useRef)();
    // Function to close the menu
    const closeMenu = (0, react_1.useCallback)(() => setMenuOpen(false), []);
    const openMenu = (0, react_1.useCallback)(() => setMenuOpen(true), []);
    const ButtonOpenThread = (0, react_1.memo)(() => ((0, jsx_runtime_1.jsx)(native_base_1.IconButton, { onPress: () => {
            onOpenChatThreads();
        }, _pressed: {
            opacity: 0.7,
            background: "transparent",
        }, style: {}, size: "lg", height: 30, width: 30, 
        // background={"#ff0"}
        padding: 0, alignItems: "center", justifyItems: "center", children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faInboxFull, color: colors_1.Colors.white, size: 22 }) })));
    const MenuBox = (0, react_1.memo)(() => ((0, jsx_runtime_1.jsx)(native_base_1.Menu, { w: "400", closeOnSelect: true, onOpen: openMenu, isOpen: menuOpen, onClose: closeMenu, ref: menuRef, style: {
            backgroundColor: `${colors_1.Colors.DARK_600}`,
            borderRadius: 12,
            // paddingHorizontal: 16,
            paddingVertical: 12,
            paddingTop: 0,
            right: 20,
            shadowColor: colors_1.Colors.DARK_700,
            shadowRadius: 10,
            shadowOpacity: 1,
        }, trigger: (triggerProps) => {
            return ((0, jsx_runtime_1.jsx)(native_base_1.Pressable, { style: {
                    height: 30,
                    width: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: `${Colors.DARK_600}88`,
                }, accessibilityLabel: "Open menu", ...triggerProps, children: (0, jsx_runtime_1.jsx)(native_base_1.Stack, { justifyContent: "center", alignItems: "center", style: { height: "100%" }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faInboxFull, color: colors_1.Colors.white, size: 22 }) }) }));
        }, children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(native_base_1.VStack, { paddingY: 4, minHeight: 200, children: (0, jsx_runtime_1.jsx)(ChatThreadsSheet_1.ChatThreadsContent, { onClose: closeMenu }) }) }) })));
    const MenuOrModel = (0, react_1.memo)(() => {
        if (isTabOrDesktopDevice)
            return (0, jsx_runtime_1.jsx)(MenuBox, {});
        return (0, jsx_runtime_1.jsx)(ButtonOpenThread, {});
    });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(MenuOrModel, {}), isOpenChatThreads && ((0, jsx_runtime_1.jsx)(ChatThreadsSheet_1.default, { isOpen: isOpenChatThreads, onOpen: onOpenChatThreads, onClose: onCloseChatStyle }))] }));
};
exports.default = (0, react_1.memo)(ChatThreadsHeaderButton);
