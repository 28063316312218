"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialState = void 0;
const getInitialState = () => {
    const initialState = {
        collection: [],
        member: {},
        uiState: "INIT",
        error: null,
    };
    return initialState;
};
exports.getInitialState = getInitialState;
