"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_BG_IMAGE = exports.APP_LOGO = void 0;
exports.APP_LOGO = `

`;
exports.APP_BG_IMAGE = `
<svg width="428" height="926" viewBox="0 0 428 926" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1025_13586)">
<rect width="428" height="926" fill="#18191F"/>
<circle opacity="0.32" cx="365.5" cy="21.5" r="218" stroke="url(#paint0_linear_1025_13586)" stroke-width="3"/>
<circle opacity="0.32" cx="427.5" cy="13.8319" r="85.2394" transform="rotate(45 427.5 13.8319)" stroke="url(#paint1_linear_1025_13586)" stroke-width="3"/>
<circle opacity="0.32" cx="106.832" cy="228.584" r="46.9962" transform="rotate(45 106.832 228.584)" stroke="url(#paint2_linear_1025_13586)" stroke-width="3"/>
<circle opacity="0.32" cx="-31.5" cy="48.5" r="154" stroke="url(#paint3_linear_1025_13586)" stroke-width="3"/>
<g opacity="0.32" filter="url(#filter0_f_1025_13586)">
<circle cx="218.5" cy="31.5" r="144.5" stroke="url(#paint4_linear_1025_13586)" stroke-width="6"/>
</g>
<g opacity="0.32" filter="url(#filter1_f_1025_13586)">
<circle cx="99.5" cy="14.5" r="147.5" fill="url(#paint5_linear_1025_13586)"/>
</g>
<g opacity="0.32" filter="url(#filter2_f_1025_13586)">
<circle cx="400.5" cy="289.5" r="56.5" fill="url(#paint6_linear_1025_13586)"/>
</g>
<g opacity="0.32" filter="url(#filter3_f_1025_13586)">
<circle cx="196.5" cy="419.5" r="44.5" fill="url(#paint7_linear_1025_13586)"/>
</g>
</g>
<defs>
<filter id="filter0_f_1025_13586" x="55" y="-132" width="327" height="327" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_1025_13586"/>
</filter>
<filter id="filter1_f_1025_13586" x="-224" y="-309" width="647" height="647" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="88" result="effect1_foregroundBlur_1025_13586"/>
</filter>
<filter id="filter2_f_1025_13586" x="198" y="87" width="405" height="405" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="73" result="effect1_foregroundBlur_1025_13586"/>
</filter>
<filter id="filter3_f_1025_13586" x="6" y="229" width="381" height="381" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="73" result="effect1_foregroundBlur_1025_13586"/>
</filter>
<linearGradient id="paint0_linear_1025_13586" x1="365.5" y1="-198" x2="365.5" y2="241" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA" stop-opacity="0.07"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<linearGradient id="paint1_linear_1025_13586" x1="427.5" y1="-72.9074" x2="427.5" y2="100.571" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA" stop-opacity="0.07"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<linearGradient id="paint2_linear_1025_13586" x1="106.832" y1="180.088" x2="106.832" y2="277.08" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA" stop-opacity="0.07"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<linearGradient id="paint3_linear_1025_13586" x1="-31.5" y1="-107" x2="-31.5" y2="204" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA"/>
<stop offset="1" stop-color="#5E51EC" stop-opacity="0.13"/>
</linearGradient>
<linearGradient id="paint4_linear_1025_13586" x1="218.5" y1="-116" x2="218.5" y2="179" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<linearGradient id="paint5_linear_1025_13586" x1="99.5" y1="-133" x2="99.5" y2="162" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<linearGradient id="paint6_linear_1025_13586" x1="400.5" y1="233" x2="400.5" y2="346" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<linearGradient id="paint7_linear_1025_13586" x1="196.5" y1="375" x2="196.5" y2="464" gradientUnits="userSpaceOnUse">
<stop stop-color="#9B37EA"/>
<stop offset="1" stop-color="#5E51EC"/>
</linearGradient>
<clipPath id="clip0_1025_13586">
<rect width="428" height="926" fill="white"/>
</clipPath>
</defs>
</svg>
`;
