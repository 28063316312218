"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadImage = exports.regenerateImage = exports.deleteDocument = exports.followUpSharedDocument = exports.createSummary = exports.getShareLink = exports.getSharedSummary = exports.getSummary = exports.getDocuments = exports.getAllDocuments = void 0;
const axios_1 = require("axios");
const streaming_1 = require("./streaming");
const interceptorsCached_1 = require("./interceptorsCached");
const interceptorsAnonAuth_1 = require("./interceptorsAnonAuth");
const document_1 = require("../helper/document");
const application_host_1 = require("../common/application-host");
const apis_1 = require("../common/apis");
const error_1 = require("../common/error");
const EXISTENT_SHARED_DOCUMENT_MESSAGE = 'Shared document already exists!';
const DOCUMENT_PAYLOAD = {
    attitude: "",
    background: "Expert in analysing documents.",
    characterName: "Document Doctor",
    description: "",
    generationId: "",
};
function getAllDocuments() {
    return (interceptorsAnonAuth_1.networkCall
        .get(`${apis_1.APP_CONSTANTS.documents.share}?userId=${application_host_1.SUMMARAIZER_ADMIN}&limit=100`).then((response) => (!response.success || response.error
        ? new error_1.APIError(response.error?.message)
        : response.data)).catch((error) => new error_1.RequestError(error)));
}
exports.getAllDocuments = getAllDocuments;
;
function getDocuments() {
    return interceptorsCached_1.cachedAuthCall.get(`${apis_1.APP_CONSTANTS.documents.index}?sort=DESC`).then((response) => response.data).catch((error) => {
        if (error.code === 'ERR_CANCELED') {
            return null;
        }
        ;
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized ? 'Session Out' : undefined,
        });
    });
}
exports.getDocuments = getDocuments;
;
function getSummary(conversationId) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.summaryJson, { conversationId }).then((response) => {
        if (response.success) {
        }
        else {
            switch (response.error?.message) {
                case 'Error while getting the JSON from S3 database. Error: NoSuchKey: The specified key does not exist.':
                    return new error_1.APIError(response.error.message, { reason: 'Not Found', module: 'Summary' });
                default:
                    return new error_1.APIError(response.error?.message, { module: 'Summary' });
            }
        }
        return response.success
            ? response.data
            : new error_1.APIError(response.error?.message, { module: 'Summary' });
    }).catch((error) => new error_1.RequestError(error, { module: 'Summary' }));
}
exports.getSummary = getSummary;
;
function getSharedSummary(shareId) {
    return interceptorsCached_1.cachedAuthCall.get(`${apis_1.APP_CONSTANTS.documents.share}/${shareId}`).then((response) => (response.success ? response.data : new error_1.APIError(response.error?.message, { module: 'Summary' }))).catch((error) => new error_1.RequestError(error, { module: 'Summary' }));
}
exports.getSharedSummary = getSharedSummary;
;
function getShareLink(conversationId, fileId) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.share, {
        conversationId,
        fileId,
    }).then((response) => (response.success || response.error?.message === 'SharedId already exists'
        ? response.data
        : new error_1.APIError(response.error?.message))).catch((error) => new error_1.RequestError(error));
}
exports.getShareLink = getShareLink;
;
function createSummary(file, streamingHandler, requestHandler) {
    return (0, streaming_1.stream)(
    // `${ADD_API}${APP_CONSTANTS.documents.createSummary}`,
    `${application_host_1.ADD_API}${apis_1.APP_CONSTANTS.documents.createSummaryGemini}`, file, DOCUMENT_PAYLOAD, streamingHandler).then((response) => {
        if (typeof response !== 'string') {
            return new error_1.APIError(response.error?.message, { module: 'Summary' });
        }
        requestHandler(response);
    }).catch((error) => {
        if (error instanceof error_1.AuthenticationError) {
            console.error('Authentication Error');
        }
        console.error(error);
    });
}
exports.createSummary = createSummary;
;
function followUpSharedDocument(shareId) {
    return interceptorsCached_1.cachedAuthCall.post(apis_1.APP_CONSTANTS.documents.follow, { shareId }).then((response) => {
        if ((!response.success || response.error)
            && response.error?.message !== EXISTENT_SHARED_DOCUMENT_MESSAGE) {
            return new error_1.APIError(response.error?.message, { module: 'Summary' });
        }
        return response.data;
    }).catch((error) => {
        return new error_1.RequestError(error, {
            reason: error.response?.status === axios_1.HttpStatusCode.Unauthorized ? 'Session Out' : undefined
        });
    });
}
exports.followUpSharedDocument = followUpSharedDocument;
;
function deleteDocument(fileId) {
    return interceptorsCached_1.cachedAuthCall
        .delete(`${apis_1.APP_CONSTANTS.documents.index}/${fileId}`)
        .then((response) => response.success ? response : new error_1.APIError(response.error.message))
        .catch((error) => new error_1.RequestError(error));
}
exports.deleteDocument = deleteDocument;
;
function regenerateImage(documentJson) {
    const { character_id, imageGenerationId, conversationId, newCharacterId } = documentJson;
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('payload', JSON.stringify({
        modelId: 'black-forest-labs/flux-schnell',
        prompt: (0, document_1.getMarkdownContent)(documentJson.summaryResponse, 'Image Prompt'),
        styleString: 'An oil painting depicting',
        aspectRatio: '16:9',
        characterId: newCharacterId,
        conversationId,
        character_id,
        imageGenerationId,
    }));
    return (interceptorsCached_1.cachedAuthCall
        .post(apis_1.APP_CONSTANTS.documents.regenerateImage, formData, config).then((response) => (response.success ? response.data : new error_1.APIError(response.error?.message))).catch((error) => new error_1.RequestError(error)));
}
exports.regenerateImage = regenerateImage;
;
function uploadImage(image, documentJson) {
    const { conversationId, character_id, newCharacterId, imageGenerationId } = documentJson;
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const formData = new FormData();
    formData.append('file', image);
    formData.append('payload', JSON.stringify({
        modelId: 'black-forest-labs/flux-schnell',
        prompt: '',
        styleString: 'An oil painting depicting',
        aspectRatio: '16:9',
        characterId: newCharacterId,
        conversationId,
        character_id,
        imageGenerationId,
    }));
    return (interceptorsCached_1.cachedAuthCall
        .post(apis_1.APP_CONSTANTS.documents.regenerateImage, formData, config).then((response) => (response.success ? response.data : new error_1.APIError(response.error?.message))).catch((error) => new error_1.RequestError(error)));
}
exports.uploadImage = uploadImage;
;
