"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const useDevice_1 = require("../../../hooks/useDevice");
const AppText_1 = require("../../../component/AppText");
const AppIcons_1 = require("../../../component/AppIcons");
const colors_1 = require("../../../common/colors");
const spacing_1 = require("../../../common/spacing");
const Ellipse_168_svg_1 = require("../../../assets/images/Ellipse 168.svg");
const Ellipse_169_svg_1 = require("../../../assets/images/Ellipse 169.svg");
function NoDocument({ style, ...restProps }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.container, style], ...restProps, children: [(0, jsx_runtime_1.jsxs)(react_native_1.View, { style: styles.imageContainer, children: [(0, jsx_runtime_1.jsx)(AppIcons_1.NoDocumentImage, { style: styles.noDocumentImage }), (0, jsx_runtime_1.jsx)(Ellipse_168_svg_1.ReactComponent, { style: styles.ellipsis1 }), (0, jsx_runtime_1.jsx)(Ellipse_169_svg_1.ReactComponent, { style: styles.ellipsis2 })] }), (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.textArea, isMobileScreen && styles.textAreaMobile], children: [(0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [styles.title, isMobileScreen && styles.titleMobile], children: "Upload your first document" }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [styles.paragraph, isMobileScreen && styles.paragraphMobile], children: "Talktoo Documents allows you to import PDF files, get on-point summaries of its content, and it even lets you ask questions and have full conversations with it!" })] })] }));
}
exports.default = NoDocument;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingTop: 131.225, // background for blur effect
    },
    imageContainer: {
        width: 405,
        maxWidth: '100%',
        alignItems: 'center',
    },
    noDocumentImage: {
        zIndex: 1,
    },
    ellipsis1: {
        position: 'absolute',
        bottom: -142.715,
        right: -139.225,
    },
    ellipsis2: {
        position: 'absolute',
        top: -131.225,
        left: -138.04,
    },
    textArea: {
        width: 627,
        maxWidth: '100%',
        gap: spacing_1.Spacing.SPACING_XL,
        alignItems: 'center',
        paddingHorizontal: spacing_1.Spacing.SPACING_5XL,
        marginTop: 24,
    },
    textAreaMobile: {
        marginTop: 0,
        paddingHorizontal: 20
    },
    title: {
        color: 'white',
        fontSize: 32,
        lineHeight: 44.8,
    },
    titleMobile: {
        fontSize: 24,
        lineHeight: 33.6,
    },
    paragraph: {
        color: colors_1.Colors.DARK_200,
        textAlign: 'center',
        fontSize: 16,
        lineHeight: 26,
    },
    paragraphMobile: {
        fontSize: 14,
        lineHeight: 22,
    },
});
