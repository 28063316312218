"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const react_native_1 = require("react-native");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const CloseButton = ({ buttonSize, iconSize = 16, inPlace, verticalOffset = 24, horizontalOffset = 24, onClose }) => {
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: onClose, style: [styles.button, {
                width: buttonSize,
                height: buttonSize,
                position: inPlace ? 'relative' : 'absolute',
                top: verticalOffset,
                right: horizontalOffset,
            }], children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimes, size: iconSize, color: "white" }) }));
};
const styles = react_native_1.StyleSheet.create({
    button: {
        backgroundColor: '#00000024',
        width: 32,
        height: 32,
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 30,
    },
});
exports.default = CloseButton;
