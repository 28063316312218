"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAppError = exports.selectViewedImage = exports.selectCharacterFilteredCategory = exports.selectShouldShowCurrentChats = exports.selectWasNewCharacterCreated = exports.selectIsCharacterOpen = exports.selectIsCharacterSharing = exports.selectIsSharingApp = exports.selectIsOpenCollection = exports.selectPickedCollection = exports.selectShouldInsertUsername = exports.selectIsLogging = exports.selectCookiesAccepted = exports.selectShouldShowSidebar = void 0;
const selectShouldShowSidebar = (state) => state.appState.shouldShowSidebar;
exports.selectShouldShowSidebar = selectShouldShowSidebar;
const selectCookiesAccepted = (state) => state.appState.wasCookiesAccepted;
exports.selectCookiesAccepted = selectCookiesAccepted;
const selectIsLogging = (state) => state.appState.isLogging;
exports.selectIsLogging = selectIsLogging;
const selectShouldInsertUsername = (state) => state.appState.shouldInsertUsername;
exports.selectShouldInsertUsername = selectShouldInsertUsername;
const selectPickedCollection = (state) => state.appState.pickedCollection;
exports.selectPickedCollection = selectPickedCollection;
const selectIsOpenCollection = (state) => state.appState.isCollectionOpen;
exports.selectIsOpenCollection = selectIsOpenCollection;
const selectIsSharingApp = (state) => state.appState.isSharingApp;
exports.selectIsSharingApp = selectIsSharingApp;
const selectIsCharacterSharing = (state) => state.appState.isCharacterSharing;
exports.selectIsCharacterSharing = selectIsCharacterSharing;
const selectIsCharacterOpen = (state) => state.appState.isCharacterOpen;
exports.selectIsCharacterOpen = selectIsCharacterOpen;
const selectWasNewCharacterCreated = (state) => state.appState.wasNewCharacterCreated;
exports.selectWasNewCharacterCreated = selectWasNewCharacterCreated;
const selectShouldShowCurrentChats = (state) => state.appState.shouldShowCurrentChats;
exports.selectShouldShowCurrentChats = selectShouldShowCurrentChats;
const selectCharacterFilteredCategory = (state) => state.appState.characterFilteredCategory;
exports.selectCharacterFilteredCategory = selectCharacterFilteredCategory;
const selectViewedImage = (state) => state.appState.viewedImage;
exports.selectViewedImage = selectViewedImage;
const selectAppError = (state) => state.appState.appError;
exports.selectAppError = selectAppError;
