"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubHeading = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_safe_area_context_1 = require("react-native-safe-area-context");
const colors_1 = require("../../common/colors");
const useDevice_1 = require("../../hooks/useDevice");
const AppText_1 = require("../../component/AppText");
const react_native_1 = require("react-native");
const SubHeading = ({ applySearch, heading, applyInset, ...props }) => {
    const insets = (0, react_native_safe_area_context_1.useSafeAreaInsets)();
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    return ((0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.DARK_200, fontSize: 16, lineHeight: applySearch || isWebDevice ? 32 : undefined, marginTop: applySearch ? 0 : isMobileScreen ? 2 : 1, marginBottom: applySearch ? 0 : isMobileScreen ? 2.5 : 4, style: {
            textTransform: "uppercase",
            paddingLeft: applyInset && !isMobileScreen ? insets.left || react_native_1.Platform.OS === 'web' ? 38 : 20 : 0,
        }, ...props, children: heading }));
};
exports.SubHeading = SubHeading;
