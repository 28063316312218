"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserQuota = exports.setActiveSubscription = exports.subscription = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const thunks_1 = require("../store/thunks");
const initialState = {
    subscription: null,
    quota: {
        uid: 0,
        // firebase uid for logged in users
        // App user id for anonymous users.
        conversations: {
            quota: 5,
            consumed: 0, // Pending messages which he can send
        },
        characters: {
            quota: 3,
            consumed: 0, // pending messages
        },
    },
};
exports.subscription = (0, toolkit_1.createSlice)({
    name: "subscriptionSlice",
    initialState: initialState,
    reducers: {
        setActiveSubscription: (state, action) => {
            state.subscription = action.payload;
        },
        setUserQuota: (state, action) => {
            state.quota = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(thunks_1.logOut.fulfilled, () => exports.subscription.getInitialState());
    },
});
_a = exports.subscription.actions, exports.setActiveSubscription = _a.setActiveSubscription, exports.setUserQuota = _a.setUserQuota;
