"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_redux_1 = require("react-redux");
const useDevice_1 = require("../../hooks/useDevice");
const container_1 = require("../../component/container");
const store_1 = require("../../store");
const appState_1 = require("../../store/selectors/appState");
const appState_2 = require("../../slices/appState");
const ANIMATION_DURATION = 250;
const SCREEN = react_native_1.Dimensions.get("screen");
const MAX_WIDTH = SCREEN.width;
const MAX_HEIGHT = SCREEN.height;
function ImageViewer() {
    const { deviceWidth, deviceHeight } = (0, useDevice_1.useDevice)();
    const dispatch = (0, store_1.useAppDispatch)();
    const viewedImage = (0, react_redux_1.useSelector)(appState_1.selectViewedImage);
    const { current: animatedImageWidth } = (0, react_1.useRef)(new react_native_1.Animated.Value(0));
    const { current: animatedImageHeight } = (0, react_1.useRef)(new react_native_1.Animated.Value(0));
    const { current: animatedImagePageY } = (0, react_1.useRef)(new react_native_1.Animated.Value(0));
    const { current: animatedImagePageX } = (0, react_1.useRef)(new react_native_1.Animated.Value(0));
    const imageDimensions = (0, react_1.useMemo)(() => {
        if (!viewedImage)
            return { width: 0, height: 0 };
        const aspectRatio = viewedImage.width / viewedImage.height;
        let width = MAX_WIDTH;
        let height = width / aspectRatio;
        if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width = height * aspectRatio;
        }
        return { width, height };
    }, [viewedImage]);
    (0, react_1.useEffect)(() => {
        if (viewedImage) {
            const { width: finalWidth, height: finalHeight } = imageDimensions;
            const initialYPosition = viewedImage.pageY - (deviceHeight - viewedImage.height) / 2;
            const initialXPosition = viewedImage.pageX - (deviceWidth - viewedImage.width) / 2;
            react_native_1.Animated.parallel([
                react_native_1.Animated.timing(animatedImageWidth, {
                    toValue: viewedImage.width,
                    useNativeDriver: true,
                    duration: 0,
                }),
                react_native_1.Animated.timing(animatedImageHeight, {
                    toValue: viewedImage.height,
                    useNativeDriver: true,
                    duration: 0,
                }),
                react_native_1.Animated.timing(animatedImagePageY, {
                    toValue: initialYPosition,
                    useNativeDriver: true,
                    duration: 0,
                }),
                react_native_1.Animated.timing(animatedImagePageX, {
                    toValue: initialXPosition,
                    useNativeDriver: true,
                    duration: 0,
                }),
            ]).start(() => {
                react_native_1.Animated.parallel([
                    react_native_1.Animated.timing(animatedImageWidth, {
                        toValue: finalWidth,
                        useNativeDriver: true,
                        duration: ANIMATION_DURATION,
                    }),
                    react_native_1.Animated.timing(animatedImageHeight, {
                        toValue: finalHeight,
                        useNativeDriver: true,
                        duration: ANIMATION_DURATION,
                    }),
                    react_native_1.Animated.timing(animatedImagePageY, {
                        toValue: 0,
                        useNativeDriver: true,
                        duration: ANIMATION_DURATION,
                    }),
                    react_native_1.Animated.timing(animatedImagePageX, {
                        toValue: 0,
                        useNativeDriver: true,
                        duration: ANIMATION_DURATION,
                    }),
                ]).start();
            });
        }
    }, [viewedImage, imageDimensions, deviceWidth, deviceHeight]);
    const handleClose = (0, react_1.useCallback)(() => {
        if (viewedImage) {
            const initialYPosition = viewedImage.pageY - (deviceHeight - viewedImage.height) / 2;
            const initialXPosition = viewedImage.pageX - (deviceWidth - viewedImage.width) / 2;
            react_native_1.Animated.parallel([
                react_native_1.Animated.timing(animatedImageWidth, {
                    toValue: viewedImage.width,
                    useNativeDriver: true,
                    duration: ANIMATION_DURATION,
                }),
                react_native_1.Animated.timing(animatedImageHeight, {
                    toValue: viewedImage.height,
                    useNativeDriver: true,
                    duration: ANIMATION_DURATION,
                }),
                react_native_1.Animated.timing(animatedImagePageY, {
                    toValue: initialYPosition,
                    useNativeDriver: true,
                    duration: ANIMATION_DURATION,
                }),
                react_native_1.Animated.timing(animatedImagePageX, {
                    toValue: initialXPosition,
                    useNativeDriver: true,
                    duration: ANIMATION_DURATION,
                }),
            ]).start(() => {
                dispatch((0, appState_2.resetViewedImage)());
            });
        }
    }, [viewedImage, deviceWidth, deviceHeight, dispatch]);
    return ((0, jsx_runtime_1.jsx)(container_1.AppModal, { isOpen: Boolean(viewedImage), onClose: handleClose, openAnimation: "none", closeAnimation: "none", style: styles.modal, children: (0, jsx_runtime_1.jsx)(react_native_1.Animated.Image, { source: { uri: viewedImage?.uri }, resizeMode: "contain", style: [
                styles.image,
                {
                    width: animatedImageWidth,
                    height: animatedImageHeight,
                    transform: [
                        { translateY: animatedImagePageY },
                        { translateX: animatedImagePageX },
                    ],
                }
            ] }) }));
}
exports.default = ImageViewer;
;
const styles = react_native_1.StyleSheet.create({
    modal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        flex: 1,
        position: 'relative',
    },
});
