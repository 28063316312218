"use strict";
// import analytics from "@react-native-firebase/analytics";
// const Analytics = analytics();
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnalytics = void 0;
/**
 *
 * @returns
 */
const useAnalytics = () => ({
    // Simple event with category
    logAppEvent: async (eventName, category) => {
        // Event Name, Event Category
        // Analytics.logEvent(eventName, { category });
    },
    logEvent: async (eventName, params = {}) => {
        console.log("logging new...", eventName);
        // Analytics.logEvent(eventName, params);
    },
    logScreenView: async (currentRouteName) => {
        // Analytics.logScreenView({
        //   screen_name: currentRouteName,
        //   screen_class: currentRouteName,
        // });
    },
    setEnabled: (enabled) => {
        // Analytics.setAnalyticsCollectionEnabled(enabled);
    },
    setUserId: (userId) => {
        // Analytics.setUserId(userId);
    },
    setUserProperty: (name, property) => {
        // Analytics.setUserProperty(name, property);
    },
});
exports.useAnalytics = useAnalytics;
//Reserved Events
// first_visit : the first time a user visits a website or launches an Android instant app with Analytics enabled
