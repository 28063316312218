"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetAppError = exports.resetViewedImage = exports.setAppError = exports.setViewedImage = exports.setShouldShowCurrentChats = exports.setShouldShowSideBar = exports.setCharacterFilteredCategory = exports.setWasNewCharacterCreated = exports.setIsCharacterOpen = exports.setIsCharacterSharing = exports.setIsSharingApp = exports.setPickedCollection = exports.setIsCollectionOpen = exports.setShouldInsertUsername = exports.setIsLogging = exports.setCookiesAccepted = exports.appStateSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    wasCookiesAccepted: false,
    isLogging: false,
    shouldInsertUsername: false,
    isCollectionOpen: false,
    pickedCollection: null,
    isSharingApp: false,
    isCharacterSharing: false,
    isCharacterOpen: false,
    wasNewCharacterCreated: false,
    characterFilteredCategory: '',
    shouldShowSidebar: true,
    shouldShowCurrentChats: false,
    viewedImage: null,
    appError: null,
};
exports.appStateSlice = (0, toolkit_1.createSlice)({
    name: "userOptionsSlice",
    initialState: initialState,
    reducers: {
        setCookiesAccepted: (state, action) => {
            state.wasCookiesAccepted = action.payload;
        },
        setIsLogging: (state, action) => {
            state.isLogging = action.payload;
        },
        setShouldInsertUsername: (state, action) => {
            state.shouldInsertUsername = action.payload;
        },
        setIsCollectionOpen: (state, action) => {
            state.isCollectionOpen = action.payload;
        },
        setPickedCollection: (state, action) => {
            state.pickedCollection = action.payload;
        },
        setIsSharingApp: (state, action) => {
            state.isSharingApp = action.payload;
        },
        setIsCharacterSharing: (state, action) => {
            state.isCharacterSharing = action.payload;
        },
        setIsCharacterOpen: (state, action) => {
            state.isCharacterOpen = action.payload;
        },
        setWasNewCharacterCreated: (state, action) => {
            state.wasNewCharacterCreated = action.payload;
        },
        setShouldShowSideBar: (state, action) => {
            state.shouldShowSidebar = action.payload;
        },
        setShouldShowCurrentChats: (state, action) => {
            state.shouldShowCurrentChats = action.payload;
        },
        setCharacterFilteredCategory: (state, action) => {
            state.characterFilteredCategory = action.payload;
        },
        setViewedImage: (state, action) => {
            state.viewedImage = action.payload;
        },
        setAppError: (state, action) => {
            state.appError = action.payload;
        },
        resetViewedImage: (state) => {
            state.viewedImage = null;
        },
        resetAppError: (state) => {
            state.appError = null;
        },
        reset: () => initialState,
    },
});
_a = exports.appStateSlice.actions, exports.setCookiesAccepted = _a.setCookiesAccepted, exports.setIsLogging = _a.setIsLogging, exports.setShouldInsertUsername = _a.setShouldInsertUsername, exports.setIsCollectionOpen = _a.setIsCollectionOpen, exports.setPickedCollection = _a.setPickedCollection, exports.setIsSharingApp = _a.setIsSharingApp, exports.setIsCharacterSharing = _a.setIsCharacterSharing, exports.setIsCharacterOpen = _a.setIsCharacterOpen, exports.setWasNewCharacterCreated = _a.setWasNewCharacterCreated, exports.setCharacterFilteredCategory = _a.setCharacterFilteredCategory, exports.setShouldShowSideBar = _a.setShouldShowSideBar, exports.setShouldShowCurrentChats = _a.setShouldShowCurrentChats, exports.setViewedImage = _a.setViewedImage, exports.setAppError = _a.setAppError, exports.resetViewedImage = _a.resetViewedImage, exports.resetAppError = _a.resetAppError;
