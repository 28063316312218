"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const react_redux_1 = require("react-redux");
const useDevice_1 = require("../../hooks/useDevice");
const rootNavigation_1 = require("../../navigation/rootNavigation");
const user_1 = require("../../store/selectors/user");
const colors_1 = require("../../common/colors");
const AppText_1 = require("../AppText");
function ProfileIcon(props) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    const userName = (0, react_redux_1.useSelector)(user_1.selectDisplayName);
    const [firstName, lastName] = userName
        ? userName.split(' ')
        : ['', ''];
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => (0, rootNavigation_1.navigate)('Account'), ...props, children: (0, jsx_runtime_1.jsx)(native_base_1.Avatar, { size: isMobileScreen ? 7 : 10, bg: colors_1.Colors.PRIMARY_100, children: (0, jsx_runtime_1.jsx)(AppText_1.AppText, { color: colors_1.Colors.PRIMARY_700, fontSize: isMobileScreen ? 12 : 20, fontWeight: 500, children: `${firstName?.[0] || ''}${lastName?.[0] || ''}` }) }) }));
}
exports.default = ProfileIcon;
