"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const native_base_1 = require("native-base");
const react_1 = require("react");
const useDevice_1 = require("../../hooks/useDevice");
const colors_1 = require("../../common/colors");
const MesssageBoxInput = ({ children, editable, audioMode, ...rest }) => {
    const { isWebDevice, isMobileScreen } = (0, useDevice_1.useDevice)();
    const defaultStyle = (0, react_1.useMemo)(() => {
        return {
            backgroundColor: colors_1.Colors.DARK_700,
            color: rest?.defaultValue || audioMode
                ? colors_1.Colors.WHITE_SECONDARY
                : isWebDevice && isMobileScreen
                    ? colors_1.Colors.DARK_300
                    : colors_1.Colors.DARK_200,
            fontSize: isWebDevice && isMobileScreen ? 16 : undefined,
            lineHeight: isWebDevice && isMobileScreen ? 20 : undefined,
            // maxHeight: 150,
            minHeight: 4,
            textAlignVertical: "top",
        };
    }, [rest?.defaultValue, audioMode]);
    // Style for rightElement & Left element stack
    const defaultStackStyle = (0, react_1.useMemo)(() => {
        return {
            bg: colors_1.Colors.DARK_700,
        };
    }, []);
    const defaultFocusStyle = (0, react_1.useMemo)(() => {
        return {
            style: { color: colors_1.Colors.white, backgroundColor: colors_1.Colors.DARK_700 },
            selectionColor: colors_1.Colors.white,
            placeholderTextColor: colors_1.Colors.white,
            _stack: {
                background: colors_1.Colors.DARK_700,
            },
        };
    }, []);
    return ((0, jsx_runtime_1.jsx)(native_base_1.Input, { allowFontScaling: false, style: defaultStyle, 
        // autoFocus
        _focus: defaultFocusStyle, _stack: defaultStackStyle, borderColor: colors_1.Colors.DARK_500, 
        // editable={editable}
        focusOutlineColor: colors_1.Colors.DARK_500, _input: {
            selectionColor: colors_1.Colors.white,
            // @ts-ignore
            cursorColor: colors_1.Colors.white,
            borderColor: colors_1.Colors.PLACEHOLDER_DARK_COLOR,
        }, ref: rest?.inputRef, ...rest, children: children }));
};
exports.default = (0, react_1.memo)(MesssageBoxInput);
