"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAsyncSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const createAsyncSlice = ({ name = "", initialState, reducers, thunks, extraReducers, }) => {
    return (0, toolkit_1.createSlice)({
        name,
        initialState,
        reducers: {
            ...reducers,
        },
        extraReducers: (builder) => {
            for (const thunk of thunks) {
                builder
                    .addCase(thunk.pending, (state, action) => {
                    state.uiState = "IN_PROGRESS";
                })
                    .addCase(thunk.fulfilled, (state, action) => {
                    state.uiState = "SUCCESS";
                    if (Array.isArray(action.payload)) {
                        state.collection = action.payload;
                    }
                    else {
                        state.member = action.payload;
                    }
                })
                    .addCase(thunk.rejected, (state, { payload }) => {
                    state.uiState = "FAILED";
                    state.error = payload;
                });
            }
            for (const extraReducer of extraReducers || []) {
                const [actionCreator, reducer] = extraReducer;
                builder.addCase(actionCreator, reducer);
            }
        },
    });
};
exports.createAsyncSlice = createAsyncSlice;
