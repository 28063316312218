"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const colors_1 = require("../../common/colors");
function ChatButton({ active, onPress, style, ...restProps }) {
    const [pressed, setIsPressed] = (0, react_1.useState)(false);
    const handleOnPress = (0, react_1.useCallback)((event) => {
        onPress?.(event);
        setIsPressed(!pressed);
    }, [pressed, onPress]);
    return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { style: typeof style === 'function'
            ? (state) => [styles.button, style(state)]
            : [styles.button, style], onPress: handleOnPress, ...restProps, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faComments, color: active ?? pressed ? colors_1.Colors.PRIMARY_300 : 'white', size: 24 }) }));
}
exports.default = ChatButton;
;
const styles = react_native_1.StyleSheet.create({
    button: {
        width: 32,
        height: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
