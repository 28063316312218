"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_1 = require("react-native");
const react_redux_1 = require("react-redux");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const useDevice_1 = require("../../hooks/useDevice");
const user_1 = require("../../store/selectors/user");
const document_1 = require("../../store/selectors/document");
const AppText_1 = require("../AppText");
const application_host_1 = require("../../common/application-host");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const colors_1 = require("../../common/colors");
const borders_1 = require("../../common/borders");
function DocumentHeaderItem({ character, style, ...restProps }) {
    const { isMobileScreen } = (0, useDevice_1.useDevice)();
    const selectedFile = (0, react_redux_1.useSelector)(user_1.selectFile);
    const selectedDocumentImageUrl = (0, react_redux_1.useSelector)(document_1.selectDocumentImageUrl);
    return ((0, jsx_runtime_1.jsxs)(react_native_1.Pressable, { style: typeof style === 'function'
            ? (state) => [styles.container, isMobileScreen && styles.containerMobile, style(state)]
            : [styles.container, isMobileScreen && styles.containerMobile, style], ...restProps, children: [(0, jsx_runtime_1.jsx)(react_native_1.Image, { source: { uri: (character.characterType === 'document'
                        && selectedFile?.characterId == character.id
                        && selectedDocumentImageUrl) || (0, application_host_1.getImageUrl)(character) }, style: styles.image }), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { numberOfLines: 1, style: styles.title, children: character.characterName }), isMobileScreen && ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { style: styles.menu, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faEllipsisVertical, size: 16, color: 'white' }) }))] }));
}
exports.default = DocumentHeaderItem;
;
const styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: colors_1.Colors.DARK_600,
        gap: 5,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderWidth: 1,
        borderColor: colors_1.Colors.DARK_600,
        borderRadius: 2,
    },
    containerMobile: {
        maxWidth: 226,
        gap: 8,
        padding: 5,
        borderRadius: borders_1.Borders.RADIUS_XS,
    },
    image: {
        width: 32,
        height: 32,
    },
    title: {
        color: 'white',
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 19.2,
    },
    menu: {
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
