"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAccountService = void 0;
const apis_1 = require("../common/apis");
const interceptorsCached_1 = require("./interceptorsCached");
/**
 *
 * @param id
 * @returns
 */
const deleteAccountService = async (id) => {
    return new Promise(async (resolve) => {
        await interceptorsCached_1.cachedAuthCall
            .delete(`${apis_1.APP_CONSTANTS.auth.delete}/${id}`)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.deleteAccountService = deleteAccountService;
