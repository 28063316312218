"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.animateTyping = void 0;
const TYPING_SPEED = 60;
const DELAY_BEFORE_ERASE = TYPING_SPEED * 20;
const ERASE_SPEED = TYPING_SPEED / 2;
const DELAY_BEFORE_TYPING = TYPING_SPEED * 20;
function animateTyping(sourceText, typingAction) {
    const typingState = {
        lastTypeTime: performance.now(),
        typedText: '',
        sourceTextIndex: 0,
        sourceString: Array.isArray(sourceText) ? sourceText[0] : sourceText,
        shouldRunAnimation: true,
    };
    const inputType = (frameTime) => {
        if (typingState.shouldRunAnimation) {
            if (frameTime - typingState.lastTypeTime > TYPING_SPEED) {
                typingState.lastTypeTime = frameTime;
                typingState.typedText = typingState.sourceString.slice(0, typingState.typedText.length + 1);
                typingAction(typingState.typedText);
            }
            ;
            if (typingState.typedText === typingState.sourceString) {
                setTimeout(() => {
                    requestAnimationFrame(eraseType);
                }, DELAY_BEFORE_ERASE);
            }
            else {
                requestAnimationFrame(inputType);
            }
        }
    };
    const eraseType = (frameTime) => {
        if (typingState.shouldRunAnimation) {
            if (frameTime - typingState.lastTypeTime > ERASE_SPEED) {
                typingState.lastTypeTime = frameTime;
                typingState.typedText = typingState.sourceString.slice(0, typingState.typedText.length - 1);
                typingAction(typingState.typedText);
            }
            ;
            if (typingState.typedText === '') {
                if (Array.isArray(sourceText)) {
                    typingState.sourceTextIndex = (typingState.sourceTextIndex + 1) % sourceText.length;
                    typingState.sourceString = sourceText[typingState.sourceTextIndex];
                }
                setTimeout(() => {
                    requestAnimationFrame(inputType);
                }, DELAY_BEFORE_TYPING);
            }
            else {
                requestAnimationFrame(eraseType);
            }
        }
    };
    const stopAnimation = () => {
        typingState.shouldRunAnimation = false;
    };
    setTimeout(() => { inputType(typingState.lastTypeTime); }, DELAY_BEFORE_TYPING);
    return stopAnimation;
}
exports.animateTyping = animateTyping;
;
