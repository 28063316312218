"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const native_base_1 = require("native-base");
const react_1 = require("react");
// import { captureScreen } from "react-native-view-shot";
const react_redux_1 = require("react-redux");
const colors_1 = require("../../../common/colors");
const useAnalytics_1 = require("../../../hooks/useAnalytics");
const useShare_1 = require("../../../hooks/useShare");
const character_1 = require("../../../slices/character");
const SHARE_MENU_ENABLED = false;
const ShareImageButton = ({ route }) => {
    console.log(".route:", route);
    const imageName = route?.params?.name || " Image";
    const imageUrl = route?.params?.url || "";
    const { logEvent } = (0, useAnalytics_1.useAnalytics)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const character = (0, react_redux_1.useSelector)((state) => state.getUserOptions.characterProfile);
    const { shareChatImage } = (0, useShare_1.useShare)();
    const shareScreenshot = async () => {
        await logEvent(`character_shared`, {
            character: imageName,
        });
        setTimeout(async () => {
            dispatch((0, character_1.showMsgBox)(false));
            // await captureScreen({
            //   format: "png",
            //   quality: 0.8,
            // }).then(async (uri) => {
            //   await readFile(uri, "base64").then(async (base64Data) => {
            //     let base64String = "data:image/png;base64," + base64Data;
            //     console.log("base64String:...", uri);
            //     // console.log("base64String resp:...", base64Data);
            //     // @todo generate link & share.
            //     let options = {
            //       title: `Talking with ${character.characterName}`,
            //       subject: `Talking with ${character.characterName}`,
            //       // saveToFiles: true,
            //       // message: `Chat with ${character.characterName} only on TalkToo`,
            //       //`In this space,  ${character.characterName} can talktoo .`,
            //       type: "image/png",
            //       urls: [base64String],
            //     };
            //     // Share issue if picture not showing in share screen
            //     //https://github.com/react-native-share/react-native-share/issues/418
            //     RNShare.open(options)
            //       .then((_res) => {
            //         console.log("Shared image...", uri);
            //       })
            //       .catch((err) => {
            //         err && console.log("RNShare error:", err);
            //       });
            //   });
            // });
            dispatch((0, character_1.showMsgBox)(true));
        }, 500);
    };
    const handleShareChatImage = (0, react_1.useCallback)(async () => {
        await shareChatImage(imageName, imageUrl);
    }, []);
    if (!SHARE_MENU_ENABLED) {
        return ((0, jsx_runtime_1.jsx)(native_base_1.Pressable, { onPress: handleShareChatImage, children: (0, jsx_runtime_1.jsx)(native_base_1.Stack, { justifyContent: "center", alignItems: "center", style: { height: "100%" }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faShareNodes, color: colors_1.Colors.white, size: 20 }) }) }));
    }
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(native_base_1.Menu, { w: "254", closeOnSelect: true, style: {
                backgroundColor: `${colors_1.Colors.PRIMARY_700}`,
                borderRadius: 12,
                // paddingHorizontal: 16,
                paddingVertical: 12,
                right: 20,
                shadowColor: colors_1.Colors.DARK_700,
                shadowRadius: 10,
                shadowOpacity: 1,
            }, trigger: (triggerProps) => {
                return ((0, jsx_runtime_1.jsx)(native_base_1.Pressable, { style: {
                        height: 30,
                        width: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }, accessibilityLabel: "Open menu", ...triggerProps, children: (0, jsx_runtime_1.jsx)(native_base_1.Stack, { justifyContent: "center", alignItems: "center", style: { height: "100%" }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faShareNodes, color: colors_1.Colors.white, size: 20 }) }) }));
            }, children: [(0, jsx_runtime_1.jsx)(native_base_1.Menu.Item, { onPress: handleShareChatImage, style: {
                        backgroundColor: "transparent",
                        borderBottomColor: "rgba(84, 84, 88, 0.65)",
                        borderBottomWidth: 0.5,
                    }, children: (0, jsx_runtime_1.jsxs)(native_base_1.HStack, { space: 1, alignItems: "center", justifyContent: "space-between", children: [(0, jsx_runtime_1.jsx)(native_base_1.Text, { color: colors_1.Colors.white, width: 190, children: "Share Image" }), (0, jsx_runtime_1.jsx)(native_base_1.View, { width: 100, flex: 0, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faUser, color: colors_1.Colors.white, size: 20 }) })] }) }), (0, jsx_runtime_1.jsx)(native_base_1.Menu.Item, { onPress: shareScreenshot, style: { backgroundColor: "transparent" }, children: (0, jsx_runtime_1.jsxs)(native_base_1.HStack, { space: 1, alignItems: "center", justifyContent: "space-between", children: [(0, jsx_runtime_1.jsx)(native_base_1.Text, { color: colors_1.Colors.white, width: 190, children: "Share Chat" }), (0, jsx_runtime_1.jsx)(native_base_1.View, { width: 100, flex: 0, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faMessage, color: colors_1.Colors.white, size: 20 }) })] }) })] }) }));
};
exports.default = (0, react_1.memo)(ShareImageButton);
