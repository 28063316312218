"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userRegister = exports.userLogin = void 0;
const apis_1 = require("../common/apis");
const interceptors_1 = require("./interceptors");
/**
 *
 * @param data
 * @returns
 */
const userRegister = async (data) => {
    return new Promise(async (resolve) => {
        console.log("Register API:", apis_1.APP_CONSTANTS.auth.sync, data);
        await interceptors_1.authCall
            .post(apis_1.APP_CONSTANTS.auth.register, data)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.userRegister = userRegister;
/**
 *
 * @param data
 * @returns
 */
const userLogin = async (data) => {
    return new Promise(async (resolve) => {
        await interceptors_1.authCall
            .post(apis_1.APP_CONSTANTS.auth.login, data)
            .then(function (response) {
            const json = response;
            resolve(json);
        })
            .catch(function (error) {
            resolve(error);
        });
    });
};
exports.userLogin = userLogin;
