"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userLoginAsync = exports.userRegistrationAsync = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const user_1 = require("../services/user");
exports.userRegistrationAsync = (0, toolkit_1.createAsyncThunk)("service/user-registration", async (data) => {
    const res = await (0, user_1.userRegister)(data);
    return res;
});
exports.userLoginAsync = (0, toolkit_1.createAsyncThunk)("service/user-login", async (data) => {
    const res = await (0, user_1.userLogin)(data);
    return res;
});
