"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const native_base_1 = require("native-base");
const pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_redux_1 = require("react-redux");
const colors_1 = require("../../common/colors");
const user_1 = require("../../slices/user");
const ON_VALUE_OFFSET = 29;
/**
 *
 * @param param0
 * @returns
 */
const JourneyActionButtons = ({ character, ...rest }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const voiceAutoPlay = (0, react_redux_1.useSelector)((state) => state.getUserOptions.voiceAutoPlay);
    const offValue = 7;
    const startValue = (0, react_1.useRef)(new react_native_1.Animated.Value(voiceAutoPlay ? ON_VALUE_OFFSET : offValue)).current;
    const duration = 100;
    const onEnableSpeech = (0, react_1.useCallback)(async (isSelected) => {
        react_native_1.Animated.timing(startValue, {
            toValue: isSelected ? ON_VALUE_OFFSET : offValue,
            duration: duration,
            useNativeDriver: true,
        }).start();
        setTimeout(async () => {
            await dispatch((0, user_1.setVoiceAutoPlay)(isSelected));
        }, 100);
    }, [voiceAutoPlay]);
    (0, react_1.useEffect)(() => {
        react_native_1.Animated.timing(startValue, {
            toValue: voiceAutoPlay ? ON_VALUE_OFFSET : offValue,
            duration: duration,
            useNativeDriver: true,
        }).start();
    }, [voiceAutoPlay]);
    return ((0, jsx_runtime_1.jsxs)(native_base_1.HStack, { flexDirection: "row", alignItems: "center", children: [(0, jsx_runtime_1.jsx)(native_base_1.Switch, { size: react_native_1.Platform.OS === "android" ? "lg" : "sm", style: {
                // height: 10,
                }, onTrackColor: colors_1.Colors.PRIMARY_300, onToggle: onEnableSpeech, isChecked: voiceAutoPlay }), (0, jsx_runtime_1.jsx)(react_native_1.Animated.View, { style: {
                    position: "absolute",
                    // left: 0,
                    transform: [
                        {
                            translateX: startValue,
                        },
                    ],
                }, children: (0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPlay, size: 18, color: colors_1.Colors.PRIMARY_300 }) })] }));
};
exports.default = (0, react_1.memo)(JourneyActionButtons);
