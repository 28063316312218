"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Borders = void 0;
exports.Borders = {
    RADIUS_XS: 4,
    RADIUS_MD: 8,
    RADIUS_LG: 10,
    RADIUS_XL: 12,
    RADIUS_2XL: 16,
};
