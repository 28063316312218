/**
 * @format
 */

import { AppRegistry } from "react-native";
import App from "./src/App";
import Inter from './src/assets/fonts/Inter-VariableFont_slnt,wght.ttf';
import InterLight from './src/assets/fonts/Inter-Light.ttf';
import InterBold from './src/assets/fonts/Inter-Bold.ttf';
// import { AppButton } from "./component/AppButton";

// import {name as appName} from './src/app.json';

const InterFontStyles = `@font-face {
  src: url(${Inter});
  font-family: "Inter";
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}`;

const InterLightFontStyles = `@font-face {
  src: url(${InterLight});
  font-family: 'Inter-Light';
}`;

const InterBoldFontStyles = `@font-face {
  src: url(${InterBold});
  font-family: 'Inter-Bold';
}`;

const style = document.createElement('style');
style.appendChild(document.createTextNode(InterFontStyles));
style.appendChild(document.createTextNode(InterLightFontStyles));
style.appendChild(document.createTextNode(InterBoldFontStyles));
document.head.appendChild(style);

AppRegistry.registerComponent('talktoo', () => App);
AppRegistry.runApplication('talktoo', {
  rootTag: document.getElementById('root'),
});
